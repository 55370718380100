import React, {useMemo} from "react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import {DateBox, SelectBox, TagBox} from "devextreme-react";
import {
    accountsGroupsDemo,
    audiencesTitleDemo,
    tasksMessagesDemo, tasksResultsDemo,
    tasksStatusesDemo
} from "../../../../utils/demo-data";
import {saveFile} from "../../../../utils/helpers";

export const TaskStatistics = (props) => {
    const {
        hideInfo,
        popupVisible,
        statsDetail,
        openGenerateReportModal,
    } = props;

    const generateReportOptions = useMemo(() => {
        return {
            type: 'default',
            stylingMode: 'contained',
            text:'Generate report',
            onClick: () => {
                openGenerateReportModal();
                hideInfo();
            },
        }
    }, []);
    const exportSkippedDataOptions = useMemo(() => {
        return {
            type: 'default',
            stylingMode: 'contained',
            text:'Export skipped',
            onClick: () => {
                const rows = [
                    ["skipped1", "task1", "blabla1"],
                    ["skipped2", "task2", "blabla2"]
                ];

                let csvContent = "data:text/csv;charset=utf-8,"
                    + rows.map(e => e.join(",")).join("\n");
                saveFile('skipped-task-data', csvContent);
                hideInfo();
            },
        }
    }, []);
    const exportUndeliveredOptions = useMemo(() => {
        return {
            type: 'default',
            stylingMode: 'contained',
            text:'Exporting undelivered',
            onClick: () => {
                const rows = [
                    ["undelivered1", "task1", "yesyes1"],
                    ["undelivered2", "task2", "yesyes2"],
                    ["undelivered3", "task3", "yesyes3"],
                    ["undelivered4", "task4", "yesyes4"],
                    ["undelivered5", "task5", "yesyes5"],
                ];

                let csvContent = "data:text/csv;charset=utf-8,"
                    + rows.map(e => e.join(",")).join("\n");
                saveFile('undelivered-task-data', csvContent);
                hideInfo();
            },
        }
    }, []);
    const cancelOptions = useMemo(() => {
        return {
            type: 'danger',
            stylingMode: 'contained',
            text:'Cancel',
            onClick: ()=>{
                hideInfo();
            },
        }
    }, []);

    if (!statsDetail) {
        return <></>;
    }

    return (
        <Popup
            visible={popupVisible}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            title={'Task statistics'}
            container=".dx-viewport"
            width={900}
            height={650}
        >
            <TextBox
                defaultValue={statsDetail.taskName}
                className={'edit-task-item'}
                label={'Task name'}
                disabled={true}
            />

            <DateBox
                defaultValue={new Date(statsDetail.startDate)}
                type="datetime"
                label={'Date and time to start task'}
                className={'edit-task-item'}
                disabled={true}
            />

            <TagBox
                items={accountsGroupsDemo}
                showSelectionControls={true}
                applyValueMode="useButtons"
                label={'Account groups'}
                displayExpr="name"
                valueExpr="id"
                defaultValue={statsDetail.accountsGroupsIds}
                className={'edit-task-item'}
                disabled={true}
            />

            <TagBox
                items={audiencesTitleDemo}
                showSelectionControls={true}
                applyValueMode="useButtons"
                label={'Audiences'}
                displayExpr="name"
                valueExpr="id"
                defaultValue={statsDetail.audiencesIds}
                className={'edit-task-item'}
                disabled={true}
            />

            <SelectBox
                items={tasksMessagesDemo}
                label={'Message'}
                displayExpr="name"
                valueExpr="id"
                defaultValue={statsDetail.messageId}
                className={'edit-task-item'}
                disabled={true}
            />

            <SelectBox
                items={tasksStatusesDemo}
                label={'Task status'}
                displayExpr="name"
                valueExpr="id"
                defaultValue={statsDetail.statusId}
                className={'edit-task-item'}
                disabled={true}
            />

            <SelectBox
                items={tasksResultsDemo}
                label={'Results'}
                displayExpr="name"
                valueExpr="id"
                defaultValue={statsDetail.resultOfFinishingId}
                className={'edit-task-item'}
                disabled={true}
            />

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={generateReportOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={exportSkippedDataOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={exportUndeliveredOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={cancelOptions}
            />
        </Popup>
    )
}
