import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import {CheckBox, TextArea, Toast} from "devextreme-react";
import './EditInvite.scss';

export const EditInvite = (props) => {
    const {
        hideInfo,
        popupVisible,
        inviteDetail,
        setEditInviteDetail,
        messages,
        setMessages,
    } = props;
    const [inviteValue, setInviteValue] = useState('');
    const [disablePreviewPage, setDisablePreviewPage] = useState(false);
    const [disableSendingMessage, setDisableSendingMessage] = useState(false);
    const [groupLinkText, setGroupLinkText] = useState('');
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    const onInviteValueChange = useCallback((e) => {
        setInviteValue(e.value);
    }, [setInviteValue]);

    const onDisablePreviewPageCheckboxValueChanged = useCallback((args) => {
        setDisablePreviewPage(args.value);
    }, []);

    const onDisableSendingMessageCheckboxValueChanged = useCallback((args) => {
        setDisableSendingMessage(args.value);
    }, []);

    const onGroupLinkTextChanged = useCallback((e) => {
        setGroupLinkText(e.value);
    }, []);

    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    const toastErrorFillGaps = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: "error",
            message: `Please fill all gaps!`,
        });
    }

    const clearValues = () => {
        setInviteValue('');
        setDisablePreviewPage(false);
        setDisableSendingMessage(false);
        setGroupLinkText('');
        setEditInviteDetail(undefined);
    }

    const saveOptions = useMemo(() => {
        return {
            type: 'success',
            stylingMode: 'contained',
            text:'Save',
            onClick: () => {
                if (!inviteValue || !groupLinkText) {
                    toastErrorFillGaps();
                    return;
                }

                if (!inviteDetail) {
                    const newInvite = {
                        id: messages.length ? messages[messages.length - 1].id + 1 : 1,
                        messageName: inviteValue,
                        typeOfMessageId: 6,
                        disablePreviewPage,
                        disableSendingMessage,
                        text: groupLinkText,
                        files: null,
                    }
                    setMessages([...messages, newInvite])
                } else {
                    const filteredInvites = [...messages].filter((message) => message.id !== inviteDetail.id);
                    const editedInvite = {
                        id: inviteDetail.id,
                        messageName: inviteValue,
                        typeOfMessageId: 6,
                        disablePreviewPage,
                        disableSendingMessage,
                        text: groupLinkText,
                        files: null,
                    }
                    setMessages([...filteredInvites, editedInvite].sort((a, b) => a.id - b.id));
                }
                clearValues();
                hideInfo();
            },
        }
    }, [messages, disablePreviewPage, disableSendingMessage, inviteValue, groupLinkText]);
    const cancelOptions = useMemo(() => {
        return {
            type: 'danger',
            stylingMode: 'contained',
            text:'Cancel',
            onClick: ()=>{
                clearValues();
                hideInfo();
            },
        }
    }, []);

    useEffect(() => {
        if (inviteDetail) {
            setInviteValue(inviteDetail.messageName);
            setDisablePreviewPage(inviteDetail.disablePreviewPage);
            setDisableSendingMessage(inviteDetail.disableSendingMessage);
            setGroupLinkText(inviteDetail.text);
        }
    }, [inviteDetail, popupVisible]);

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={inviteDetail ? 'Edit invite' : 'Create invite'}
                container=".dx-viewport"
                width={700}
                height={525}
            >
                <TextBox
                    value={inviteValue}
                    onValueChanged={onInviteValueChange}
                    className={'add-message-input'}
                    label={'Invite name'}
                />

                <div className={'edit-message-checkboxes'}>
                    <CheckBox
                        value={disablePreviewPage}
                        onValueChanged={onDisablePreviewPageCheckboxValueChanged}
                        text={`Запретить предпросмотр веб-страницы`}
                    />

                    <CheckBox
                        value={disableSendingMessage}
                        onValueChanged={onDisableSendingMessageCheckboxValueChanged}
                        text={`Запретить отправку уведомления`}
                    />
                </div>

                <TextArea
                    height={100}
                    value={groupLinkText}
                    onValueChanged={onGroupLinkTextChanged}
                    label={'Link to the group'}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={saveOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={cancelOptions}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1000}
                position={"top"}
            />
        </>
    )
}
