import React, {useCallback, useState} from "react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import {RadioGroup} from "devextreme-react";
import {messagesTypesDemo} from "../../../../utils/demo-data";

export const AddMessageConfirmation = ({popupVisible, hideInfo, openNextPopup, messageType, setMessageType}) => {
    const nextHandler = () => {
        hideInfo();
        openNextPopup();
    }

    const getCancelButtonOptions = useCallback(() => ({
        text: 'Cancel',
        stylingMode: 'contained',
        type: 'danger',
        width: 120,
        onClick: hideInfo,
    }), [hideInfo]);

    const getNextButtonOptions = useCallback(() => ({
        text: 'Continue',
        stylingMode: 'contained',
        type: 'success',
        width: 120,
        onClick: nextHandler,
    }), [nextHandler]);

    const changeMessageType = useCallback((e) => {
        setMessageType(e.value);
    }, [setMessageType]);


    return (
        <Popup
            visible={popupVisible}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            title={'Create message'}
            container=".dx-viewport"
            width={500}
            height={350}
        >
            <RadioGroup
                items={messagesTypesDemo.slice(0, messagesTypesDemo.length - 1)}
                value={messageType}
                valueExpr="id"
                displayExpr="name"
                onValueChanged={changeMessageType}
            />

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={getNextButtonOptions()}
                disabled={!messageType}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={getCancelButtonOptions()}
            />
        </Popup>
    )
}
