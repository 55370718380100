// Proxies page start ----------
export const proxiesDemo = [
    {
        id: 1,
        groupId: 1,
        host: "65.21.67.151",
        port: 28023,
        login: "gD5o3XvLjje6",
        password: "x9vj3mC79e",
        status: 'Online: ping 17 ms, speed 35 Mbps',
        addedDate: '22.01.2024/17:11',
        updatedDate: '24.01.2024/12:22',
        statusUpdatedDate: '25.01.2024/13:27',
    },
    {
        id: 2,
        groupId: 1,
        host: "185.130.105.109",
        port: 10000,
        login: "aLz5Ugd6pwXu0YkC4IRZ",
        password: "RNW78Fm5",
        status: 'Online: ping 21 ms, speed 26 Mbps',
        addedDate: '23.01.2024/14:22',
        updatedDate: '24.01.2024/13:54',
        statusUpdatedDate: '24.01.2024/22:21',
    },

    {
        id: 3,
        groupId: 2,
        host: "65.21.67.151",
        port: 28041,
        login: "aLz5Ugd6pwXu0YkC4IRZ",
        password: "RNW78Fm5",
        status: 'Offline',
        addedDate: '22.01.2024/17:11',
        updatedDate: '24.01.2024/12:22',
        statusUpdatedDate: '25.01.2024/13:27',
    },
    {
        id: 4,
        groupId: 2,
        group: "Mobil",
        host: "65.21.67.151",
        port: 28042,
        login: "C9fPC6dTso31",
        password: "RDCmEUloVRSj",
        status: 'Offline',
        addedDate: '22.01.2024/17:11',
        updatedDate: '24.01.2024/12:22',
        statusUpdatedDate: '25.01.2024/13:27',
    },
    {
        id: 5,
        groupId: 2,
        host: "65.21.67.151",
        port: 28043,
        login: "ir7DwclxARIh",
        password: "nZ8n3RHfZy",
        status: 'Online: ping 21 ms, speed 26 Mbps',
        addedDate: '22.01.2024/17:11',
        updatedDate: '24.01.2024/12:22',
        statusUpdatedDate: '25.01.2024/13:27',
    },
    {
        id: 6,
        groupId: 2,
        host: "65.21.67.151",
        port: 28044,
        login: "T43pkjgqq8bF",
        password: "XuZPGh8yZ4",
        status: 'Online: ping 33 ms, speed 37 Mbps',
        addedDate: '22.01.2024/17:11',
        updatedDate: '24.01.2024/12:22',
        statusUpdatedDate: '25.01.2024/13:27',
    },
    {
        id: 7,
        groupId: 2,
        host: "65.21.67.151",
        port: 28045,
        login: "NOAPMgvERLwd",
        password: "VlMMteBrKV",
        status: 'Online: ping 21 ms, speed 26 Mbps',
        addedDate: '22.01.2024/17:11',
        updatedDate: '24.01.2024/12:22',
        statusUpdatedDate: '25.01.2024/13:27',
    },
];

export const proxiesGroupsDemo = [
    {
        id: 1,
        name: 'LeoProxy',
    },
    {
        id: 2,
        name: 'Mobil',
    }
]

// Proxies page end ----------

// Audience page start ----------
export const audiencesDemo = [
    {
        id: 1,
        audienceTitleId: 1,
        contacts: [
            {
                id: 1,
                name: 'Ildar',
            },
            {
                id: 2,
                name: 'Vadim',
            }
        ]
    },
    {
        id: 2,
        audienceTitleId: 2,
        contacts: [
            {
                id: 1,
                name: 'Georgiy',
            },
            {
                id: 2,
                name: 'Alex',
            },
            {
                id: 3,
                name: 'Bob',
            },
            {
                id: 4,
                name: 'Evgeniy',
            }
        ]
    },
];

export const audiencesTitleDemo = [
    {
        id: 1,
        name: 'Admins',
    },
    {
        id: 2,
        name: 'Only great mans',
    }
]

// Audience page end ----------


// Telegram clients page start ----------
export const telegramClientsDemo = [
    {
        id: 1,
        name: 'Client #1',
        apiId: 'skk23Skk8sa0(gjbmasd2@124!##5dfg',
        apiHash: '#23220021235',
    },
    {
        id: 2,
        name: 'Client #2',
        apiId: '8sa0(@124!#gjbskk23Skkmasd2#5dfg',
        apiHash: '#00222233125',
    },
    {
        id: 3,
        name: 'Client #3',
        apiId: '#5sf0(gjb23Skk8mask2@12ksa4!#ddg',
        apiHash: '#52122322003',
    },
    {
        id: 4,
        name: 'Client #4',
        apiId: 'sd2@1s(gjbmakk85dfgsa02423Skk!##',
        apiHash: '#12002322235',
    },
    {
        id: 5,
        name: 'Client #5',
        apiId: 'jbmakk85df2423Skkspp9@gsa0gl))ss',
        apiHash: '#00235312222',
    },
]

// Telegram clients page end ----------

// Accounts page start ----------
export const accountsDemo = [
    {
        id: 1,
        groupId: 1,
        telegramId: 1,
        phone: '+79961259090',
        firstName: 'David',
        lastName: 'Guess',
        genderId: 1,
        avatar: 'https://www.clipartmax.com/png/full/267-2671061_y%C3%BCkle-youssefdibeyoussefdibe-profile-picture-user-male.png',
        telegramClient: 'Client #1',
        messagesSent: 23,
        statusId: 1,
    },
    {
        id: 2,
        groupId: 1,
        telegramId: 2,
        phone: '+79171332121',
        firstName: 'Vasya',
        lastName: 'Pupkin',
        genderId: 1,
        avatar: 'https://cdn1.iconfinder.com/data/icons/user-interface-4/502/81-1024.png',
        telegramClient: 'Client #2',
        messagesSent: 0,
        statusId: 2,
    },
    {
        id: 3,
        groupId: 2,
        telegramId: 3,
        phone: '+79963771112',
        firstName: 'Reggie',
        lastName: 'Brightness',
        genderId: 2,
        avatar: 'https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-Vector-PNG-Photos.png',
        telegramClient: 'Client #3',
        messagesSent: 3,
        statusId: 4,
    },
    {
        id: 4,
        groupId: 2,
        telegramId: 4,
        phone: '+79170011992',
        firstName: 'Britney',
        lastName: 'Spears',
        genderId: 2,
        avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Crystal_Clear_kdm_user_female.svg/1200px-Crystal_Clear_kdm_user_female.svg.png',
        telegramClient: 'Client #4',
        messagesSent: 9,
        statusId: 7,
    },
    {
        id: 5,
        groupId: 2,
        telegramId: 5,
        phone: '+79038991222',
        firstName: 'Sasha',
        lastName: 'Stones',
        genderId: 2,
        avatar: 'https://kingmasters.ru/images/2023/09/24/img_avatar2__2076x2076.png',
        telegramClient: 'Client #5',
        messagesSent: 84,
        statusId: 8,
    }
]

export const accountsGroupsDemo = [
    {
        id: 1,
        name: 'Great boys',
        proxiesGroups: 1,
        sendLimit: 100,
        sendLimitFrame: 150,
        sleepTime: 200,
        peerFloodTimeout: 500,
        peerFloodLimit: 600,
        peerFloodMultiplier: 22,
        peerFloodMaxTries: 3,
        ignoreCapacity: true,
        ignorePeerFlood: false,
    },
    {
        id: 2,
        name: 'Beautiful girls',
        proxiesGroups: 2,
        sendLimit: 50,
        sendLimitFrame: 80,
        sleepTime: 140,
        peerFloodTimeout: 333,
        peerFloodLimit: 222,
        peerFloodMultiplier: 67,
        peerFloodMaxTries: 7,
        ignoreCapacity: false,
        ignorePeerFlood: true,
    }
]
export const accountsStatusesDemo = [
    {
        id: 1,
        name: 'unknown',
    },
    {
        id: 2,
        name: 'ok',
    },
    {
        id: 3,
        name: 'floodwait',
    },
    {
        id: 4,
        name: 'spamblock',
    },
    {
        id: 5,
        name: 'sleep',
    },
    {
        id: 6,
        name: 'ban',
    },
    {
        id: 7,
        name: 'peerflood',
    },
    {
        id: 8,
        name: 'revoked',
    },
]

export const accountDataFormatsDemo = [
    {
        id: 1,
        name: 'CSV',
    },
    {
        id: 2,
        name: 'XML',
    },
    {
        id: 3,
        name: 'JSON',
    },
]

export const accountGendersDemo = [
    {
        id: 1,
        name: 'Male',
        shortName: 'M',
    },
    {
        id: 2,
        name: 'Female',
        shortName: 'F',
    }
]

export const accountLanguagesDemo = [
    {
        id: 1,
        name: 'Ru',
    },
    {
        id: 2,
        name: 'En',
    },
    {
        id: 3,
        name: 'Es',
    },
    {
        id: 4,
        name: 'Cn',
    },
]

export const accountImageUploadTypeDemo = [
    {
        id: 1,
        name: 'Add to existing',
    },
    {
        id: 2,
        name: 'Replace all existing',
    },
]

// Accounts page end ------------


// Messages page start ----------

export const messagesDemo = [
    {
        id: 1,
        messageName: 'Oldest message',
        typeOfMessageId: 1,
        disablePreviewPage: true,
        disableSendingMessage: false,
        text: 'Lorem ipsum',
        files: null,
    },
    {
        id: 2,
        messageName: 'Newest message',
        typeOfMessageId: 1,
        disablePreviewPage: false,
        disableSendingMessage: true,
        text: 'Lorem ipsum ipsum',
        files: null,
    },
    {
        id: 3,
        messageName: 'Photo message',
        typeOfMessageId: 1,
        disablePreviewPage: false,
        disableSendingMessage: false,
        text: 'Lorem ipsum ipsum lorem',
        files: null,
    },
    {
        id: 4,
        messageName: 'Forwarded message',
        typeOfMessageId: 1,
        disablePreviewPage: true,
        disableSendingMessage: true,
        text: 'Lorem ipsum ipsum lorem lorem ipsum',
        files: null,
    },
    {
        id: 5,
        messageName: 'Invite #1',
        typeOfMessageId: 6,
        disablePreviewPage: true,
        disableSendingMessage: true,
        text: 'https://t.me/group_name',
        files: null,
    },
]

export const messagesTypesDemo = [
    {
        id: 1,
        name: 'Text',
    },
    {
        id: 2,
        name: 'Photo',
    },
    {
        id: 3,
        name: 'Video',
    },
    {
        id: 4,
        name: 'Voice',
    },
    {
        id: 5,
        name: 'Forward',
    },
    {
        id: 6,
        name: 'Invite to Group',
    },
]

// Messages page end ------------


// Tasks page start ------------

export const tasksDemo = [
    {
        id: 1,
        taskName: 'Task #1',
        accountsGroupsIds: [1, 2],
        audiencesIds: [1],
        messageId: 1,
        statusId: 1,
        resultOfFinishingId: 1,
        startDate: 1708124400000,
        started: false,
    },
    {
        id: 2,
        taskName: 'Task #2',
        accountsGroupsIds: [2],
        audiencesIds: [1, 2],
        messageId: 2,
        statusId: 2,
        resultOfFinishingId: 2,
        startDate: 1707498000000,
        started: true,
    },
    {
        id: 3,
        taskName: 'Task #3',
        accountsGroupsIds: [1],
        audiencesIds: [2],
        messageId: 3,
        statusId: 3,
        resultOfFinishingId: 3,
        startDate: 1707328800000,
        started: true,
    },
]

export const tasksMessagesDemo = [
    {
        id: 1,
        name: 'Very good task',
    },
    {
        id: 2,
        name: 'Best task message',
    },
    {
        id: 3,
        name: 'Worst task messageeeee',
    },
]
export const tasksStatusesDemo = [
    {
        id: 1,
        name: 'new',
    },
    {
        id: 2,
        name: 'planned',
    },
    {
        id: 3,
        name: 'progress',
    },
    {
        id: 4,
        name: 'finish',
    },
    {
        id: 5,
        name: 'pause',
    },
    {
        id: 6,
        name: 'canceled',
    },
]

export const tasksResultsDemo = [
    {
        id: 1,
        name: 'Ok:1160',
    },
    {
        id: 2,
        name: 'skip:3',
    },
    {
        id: 3,
        name: 'fail:145',
    },
]

export const tasksGenerateReportTypesDemo = [
    {
        id: 1,
        name: 'Short report',
    },
    {
        id: 2,
        name: 'Detailed report',
    },
    {
        id: 3,
        name: 'A brief report for the customer',
    },
    {
        id: 4,
        name: 'Detailed report for the customer',
    },
    {
        id: 5,
        name: 'Sending log',
    },
]


// Tasks page end --------------
