import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import {Button, DateBox, SelectBox, TagBox, Toast} from "devextreme-react";
import {accountsGroupsDemo, audiencesDemo, audiencesTitleDemo, tasksMessagesDemo} from "../../../../utils/demo-data";
import './EditTask.scss';

export const EditTask = (props) => {
    const {
        hideInfo,
        popupVisible,
        taskDetail,
        setEditTaskDetail,
        tasks,
        setTasks,
    } = props;
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
        displayTime: 1000,
        position: 'top',
    });
    const [taskNameValue, setTaskNameValue] = useState('');
    const minDate = new Date();
    const [dateAndTime, setDateAndTime] = useState(new Date());
    const [accountsGroups, setAccountsGroups] = useState([]);
    const [audiences, setAudiences] = useState([]);
    const [messages, setMessages] = useState();
    const [calculateCapacityBtnDisabled, setCalculateCapacityBtnDisabled] = useState(true);

    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    const onTaskNameValueChange = useCallback((e) => {
        setTaskNameValue(e.value);
    }, [setTaskNameValue]);

    const onDateAndTimeChanged = useCallback((e) => {
        setDateAndTime(e.value);
    }, []);

    const onAccountsGroupsChange = useCallback((e) => {
        setAccountsGroups(e.value);
    }, [setAccountsGroups])

    const onAudiencesChange = useCallback((e) => {
        setAudiences(e.value);
    }, [setAudiences])

    const onMessagesChange = useCallback((e) => {
        setMessages(e.value);
    }, [setMessages])

    const toastErrorFillGaps = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: "error",
            message: `Please fill all gaps!`,
            displayTime: 1000,
            position: 'top',
        });
    }

    const clearValues = () => {
        setTaskNameValue('');
        setDateAndTime(new Date());
        setAccountsGroups([]);
        setAudiences([]);
        setMessages(undefined);
        setEditTaskDetail(undefined);
    }

    const saveOptions = useMemo(() => {
        return {
            type: 'success',
            stylingMode: 'contained',
            text:'Save',
            onClick: () => {
                if (!taskNameValue || !dateAndTime || !accountsGroups.length || !audiences.length || !messages) {
                    toastErrorFillGaps();
                    return;
                }

                if (!taskDetail) {
                    const newTask = {
                        id: tasks.length ? tasks[tasks.length - 1].id + 1 : 1,
                        taskName: taskNameValue,
                        accountsGroupsIds: accountsGroups,
                        audiencesIds: audiences,
                        messageId: messages,
                        statusId: 1,
                        resultOfFinishingId: 1,
                        startDate: dateAndTime.getTime(),
                        started: false,
                    }
                    setTasks([...tasks, newTask])
                } else {
                    const filteredTasks = [...tasks].filter((task) => task.id !== taskDetail.id);
                    const editedTask = {
                        id: taskDetail.id,
                        taskName: taskNameValue,
                        accountsGroupsIds: accountsGroups,
                        audiencesIds: audiences,
                        messageId: messages,
                        statusId: taskDetail.statusId,
                        resultOfFinishingId: taskDetail.resultOfFinishingId,
                        startDate: dateAndTime.getTime(),
                        started: taskDetail.started,
                    }
                    setTasks([...filteredTasks, editedTask].sort((a, b) => a.id - b.id));
                }
                clearValues();
                hideInfo();
            },
        }
    }, [taskNameValue, dateAndTime, accountsGroups, audiences, messages, taskDetail]);
    const cancelOptions = useMemo(() => {
        return {
            type: 'danger',
            stylingMode: 'contained',
            text:'Cancel',
            onClick: ()=>{
                clearValues();
                hideInfo();
            },
        }
    }, []);

    const calculateCapacityHandler = useCallback(() => {
        const filteredAudiences = [...audiencesDemo].filter(audience => audiences.includes(audience.id));
        const onlyContactsLengths = filteredAudiences.map((audience) => audience.contacts.length);
        const totalContacts = onlyContactsLengths
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: "success",
            message: `The number of contacts to receive the newsletter: ${totalContacts}`,
            displayTime: 3500,
            position: 'center',
        });
    }, [audiences])

    useEffect(() => {
        if (!accountsGroups.length || !audiences.length) {
            setCalculateCapacityBtnDisabled(true);
        } else {
            setCalculateCapacityBtnDisabled(false);
        }
    }, [accountsGroups, audiences])

    useEffect(() => {
        if (taskDetail) {
            setTaskNameValue(taskDetail.taskName);
            setDateAndTime(new Date(taskDetail.startDate));
            setAccountsGroups(taskDetail.accountsGroupsIds);
            setAudiences(taskDetail.audiencesIds);
            setMessages(taskDetail.messageId);
        }
    }, [taskDetail, popupVisible]);

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={'Create/edit task'}
                container=".dx-viewport"
                width={700}
                height={600}
            >
                <TextBox
                    value={taskNameValue}
                    onValueChanged={onTaskNameValueChange}
                    className={'edit-task-item'}
                    label={'Task name'}
                />

                <DateBox
                    value={dateAndTime}
                    min={minDate}
                    onValueChanged={onDateAndTimeChanged}
                    type="datetime"
                    label={'Date and time to start task'}
                    className={'edit-task-item'}
                />

                <TagBox
                    items={accountsGroupsDemo}
                    showSelectionControls={true}
                    applyValueMode="useButtons"
                    label={'Account groups'}
                    displayExpr="name"
                    valueExpr="id"
                    value={accountsGroups}
                    onValueChanged={onAccountsGroupsChange}
                    className={'edit-task-item'}
                />

                <TagBox
                    items={audiencesTitleDemo}
                    showSelectionControls={true}
                    applyValueMode="useButtons"
                    label={'Audiences'}
                    displayExpr="name"
                    valueExpr="id"
                    value={audiences}
                    onValueChanged={onAudiencesChange}
                    className={'edit-task-item'}
                />

                <SelectBox
                    items={tasksMessagesDemo}
                    label={'Message'}
                    displayExpr="name"
                    valueExpr="id"
                    value={messages}
                    onValueChanged={onMessagesChange}
                    className={'edit-task-item'}
                />

                <Button
                    type="default"
                    className={'mass-account-edit-btn'}
                    disabled={calculateCapacityBtnDisabled}
                    onClick={calculateCapacityHandler}
                >
                    Calculate the capacity
                </Button>

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={saveOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={cancelOptions}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={toastConfig.displayTime}
                position={toastConfig.position}
            />
        </>
    )
}
