import {Navigate, Route, Routes} from 'react-router-dom';
import {SingleCard} from './layouts';
import {LoginForm} from './components';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path='/login'
        element={
          <SingleCard title="Sign In with Telegram">
            <LoginForm />
          </SingleCard>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
