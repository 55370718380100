import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './accounts.scss';
import DataGrid, {
    Button as GridButton,
    Column,
    Editing,
    Form,
    GroupPanel,
    HeaderFilter,
    Item,
    Lookup,
    Pager,
    Paging,
    Popup,
    RequiredRule,
    Selection,
    Toolbar,
    ToolbarItem
} from "devextreme-react/data-grid";
import {
    accountGendersDemo,
    accountImageUploadTypeDemo,
    accountsDemo,
    accountsGroupsDemo,
    accountsStatusesDemo
} from "../../utils/demo-data";
import {Button, FileUploader, RadioGroup, SelectBox} from "devextreme-react";
import {ImportFromZip} from "../../components/modals/account-page/import-from-zip/ImportFromZip";
import DataSource from "devextreme/data/data_source";
import LoadPanel from "devextreme-react/load-panel";
import {WarningForCheck} from "../../components/modals/account-page/warning-for-check/WarningForCheck";
import {confirm} from "devextreme/ui/dialog";
import {MassAccountEdit} from "../../components/modals/account-page/mass-account-edit/MassAccountEdit";
import {EditGroup} from "../../components/modals/account-page/edit-group/EditGroup";
import {EditAccount} from "../../components/modals/account-page/edit-account/EditAccount";

const calculateNameCellValue = (cell) => {
    const name = [cell.data.firstName, cell.data.lastName].join(' ');
    const thisGender = accountGendersDemo.filter((genderId) => genderId.id === cell.data.genderId)[0];
    const value = `${name}/${thisGender.shortName}/`

    return (
        <div className={'accounts-custom-cell'}>
            {value}
            <div className={'accounts-cell-image'}>
                <img
                    src={cell.data.avatar}
                    alt={`${name}-avatar`}
                />
            </div>
        </div>
    )
};

const groupsDataSource = new DataSource({
    store: {
        data: accountsGroupsDemo,
        type: 'array',
        key: 'id',
    },
});

const customItemCreating = (args) => {
    if (!args.text) {
        args.customItem = null;
        return;
    }

    const groupsIds = accountsGroupsDemo.map((item) => item.id);
    const incrementedId = Math.max.apply(null, groupsIds) + 1;
    const newItem = {
        id: incrementedId,
        name: args.text,
    };

    args.customItem = groupsDataSource
        .store()
        .insert(newItem)
        .then(() => groupsDataSource.load())
        .then(() => newItem)
        .catch((error) => {
            throw error;
        });
};
const loaderPosition = { of: '#accounts' };

export default function Accounts() {
    const dataGridRef = useRef(null);
    const [accounts, setAccounts] = useState(accountsDemo);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [importModalVisible, setImportModalVisible] = useState(false);
    const [warningCheckModalVisible, setWarningCheckModalVisible] = useState(false);
    const [massAccountEditModalVisible, setMassAccountEditModalVisible] = useState(false);
    const [editGroupModalVisible, setEditGroupModalVisible] = useState(false);
    const [editAccountModalVisible, setEditAccountModalVisible] = useState(false);
    const [loadPanelVisible, setLoadPanelVisible] = useState(true);
    const [editGroupDetail, setEditGroupDetail] = useState();
    const [editAccountDetail, setEditAccountDetail] = useState();

    const openImportModal = useCallback(() => {
        setImportModalVisible(true);
    }, [setImportModalVisible])

    const closeImportModal = useCallback(() => {
        setImportModalVisible(false);
    }, [setImportModalVisible])

    const openEditAccountModal = useCallback((e) => {
        setEditAccountDetail(e.row.data);
        setEditAccountModalVisible(true);
    }, [setEditAccountModalVisible])

    const closeEditAccountModal = useCallback(() => {
        setEditAccountModalVisible(false);
    }, [setEditAccountModalVisible])

    const openWarningCheckModal = useCallback(() => {
        setWarningCheckModalVisible(true);
    }, [setWarningCheckModalVisible])

    const closeWarningCheckModal = useCallback(() => {
        setWarningCheckModalVisible(false);
    }, [setWarningCheckModalVisible])

    const openMassAccountEditModal = useCallback(() => {
        setMassAccountEditModalVisible(true);
    }, [setMassAccountEditModalVisible])

    const closeMassAccountEditModal = useCallback(() => {
        setMassAccountEditModalVisible(false);
    }, [setMassAccountEditModalVisible])

    const openEditGroupModal = useCallback((groupDetail) => {
        setEditGroupDetail(groupDetail);
        setEditGroupModalVisible(true);
    }, [setEditGroupModalVisible])

    const closeEditGroupModal = useCallback(() => {
        setEditGroupModalVisible(false);
    }, [setEditGroupModalVisible])

    const groupsCellRender = useCallback((cell) => {
        const group = groupsDataSource.items().filter((group) => group.id === cell.value)[0];

        return (
            <div className={'account-group-cell-row'}>
                <Button
                    className={'account-group-link'}
                    onClick={() => { openEditGroupModal(group) }}
                >
                    {group.name}
                </Button>
                <Button
                    type={'default'}
                    icon={'edit'}
                    onClick={() => { openEditGroupModal(group) }}
                />
            </div>
        );
    }, []);

    const handleSelectionChanged = (e) => {
        setSelectedAccounts(e.selectedRowKeys)
    };

    const deleteAccounts = useCallback(async () => {
        const confirmed = await confirm('Do you confirm deleting accounts?', 'Are you sure?');

        if (confirmed) {
            selectedAccounts.forEach((accountId) => {
                setAccounts(prevState => prevState.filter((account) => account.id !== accountId));
            })
            dataGridRef.current.instance.deselectAll();
        }
    }, [dataGridRef, selectedAccounts]);

    useEffect(() => {
        groupsDataSource.load()
            .then((res) => {
                setLoadPanelVisible(false);
            })
            .catch((err) => {
                setLoadPanelVisible(false);
                console.log(err);
            })
    }, []);

    let content;

    if (loadPanelVisible) {
        content = (
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={loaderPosition}
                visible={loadPanelVisible}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
        )
    } else {
        content = (
            <DataGrid
                id={'accountsGrid'}
                ref={dataGridRef}
                className={'dx-card wide-card'}
                dataSource={accounts}
                keyExpr={"id"}
                showBorders={false}
                focusedRowEnabled={false}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onSelectionChanged={handleSelectionChanged}
            >
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <Editing
                    mode="popup"
                    allowAdding={false}
                    allowUpdating={true}
                    allowDeleting={true}
                />
                <HeaderFilter visible={true} />

                <Column
                    dataField="groupId"
                    caption="Group"
                    groupIndex={0}
                    groupCellRender={groupsCellRender}
                    lookup={{
                        dataSource: groupsDataSource.items(),
                        displayExpr: 'name',
                        valueExpr: 'id',
                    }}
                >
                    <RequiredRule />
                </Column>
                <Column dataField='telegramId' allowEditing={false}>
                    <RequiredRule />
                </Column>
                <Column dataField='phone' allowEditing={false}>
                    <RequiredRule />
                </Column>
                <Column
                    caption="Firstname, lastname, gender, userpic"
                    cellRender={calculateNameCellValue}
                    allowEditing={false}
                />
                <Column dataField='firstName' visible={false}>
                    <RequiredRule />
                </Column>
                <Column dataField='lastName' visible={false}>
                    <RequiredRule />
                </Column>
                <Column
                    dataField="genderId"
                    caption="Gender"
                    visible={false}
                >
                    <RequiredRule />
                    <Lookup
                        dataSource={accountGendersDemo}
                        displayExpr="name"
                        valueExpr="id"
                    />
                </Column>
                <Column
                    dataField='avatar'
                    visible={false}
                >
                    <RequiredRule />
                </Column>
                <Column dataField='telegramClient'>
                    <RequiredRule />
                </Column>
                <Column dataField='messagesSent' allowEditing={false}>
                    <RequiredRule />
                </Column>
                <Column
                    dataField="statusId"
                    caption="Status"
                >
                    <RequiredRule />
                    <Lookup
                        dataSource={accountsStatusesDemo}
                        displayExpr="name"
                        valueExpr="id"
                    />
                </Column>
                <Column type='buttons'>
                    <GridButton
                        icon='edit'
                        onClick={openEditAccountModal}
                    />
                    <GridButton
                        name='delete'
                    />
                    <GridButton
                        icon='fa-solid fa-check-to-slot'
                        hint={'Check'}
                        onClick={openWarningCheckModal}
                    />
                </Column>

                <GroupPanel visible={true} />
                <Selection mode="multiple" />
                <Toolbar>
                    <Item name="groupPanel" />
                    <Item>
                        <Button
                            text={"Import from ZIP"}
                            icon='fa fa-file-import'
                            onClick={openImportModal}
                        />
                    </Item>
                    <Item>
                        <Button
                            text={"Mass edit"}
                            icon='fa-solid fa-pen-to-square'
                            disabled={selectedAccounts.length <= 1}
                            onClick={openMassAccountEditModal}
                        />
                    </Item>
                    <Item>
                        <Button
                            text={"Check"}
                            icon='fa-solid fa-check-to-slot'
                            disabled={selectedAccounts.length <= 1}
                            onClick={openWarningCheckModal}
                        />
                    </Item>
                    <Item>
                        <Button
                            text={"Delete"}
                            icon='fa fa-trash'
                            type="danger"
                            disabled={selectedAccounts.length === 0}
                            onClick={deleteAccounts}
                        />
                    </Item>
                </Toolbar>
            </DataGrid>
        )
    }

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Accounts</h2>
            <div className={'content-block'} id={'accounts'}>
                {content}
            </div>

            <ImportFromZip
                hideInfo={closeImportModal}
                popupVisible={importModalVisible}
            />

            <MassAccountEdit
                hideInfo={closeMassAccountEditModal}
                popupVisible={massAccountEditModalVisible}
                dataSource={groupsDataSource}
                customItemCreating={customItemCreating}
                accounts={accounts}
                setAccounts={setAccounts}
                selectedAccountsIds={selectedAccounts}
            />

            <WarningForCheck
                hideInfo={closeWarningCheckModal}
                popupVisible={warningCheckModalVisible}
            />

            <EditGroup
                hideInfo={closeEditGroupModal}
                popupVisible={editGroupModalVisible}
                groupDetail={editGroupDetail}
                groupsDataSource={groupsDataSource}
            />

            <EditAccount
                hideInfo={closeEditAccountModal}
                popupVisible={editAccountModalVisible}
                groupsDataSource={groupsDataSource}
                customItemCreating={customItemCreating}
                editAccountDetail={editAccountDetail}
                accounts={accounts}
                setAccounts={setAccounts}
            />
        </React.Fragment>
    )
};
