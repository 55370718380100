export const navigation = [
  {
    text: 'Dashboard',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Proxies',
    path: '/proxies',
    icon: 'fas fa-wifi'
  },
  {
    text: 'Audiences',
    path: '/audiences',
    icon: 'fas fa-users'
  },
  {
    text: 'Telegram-clients',
    path: '/telegram-clients',
    icon: 'fas fa-regular fa-address-card'
  },
  {
    text: 'Accounts',
    path: '/accounts',
    icon: 'fa fa-robot'
  },
  {
    text: 'Messages',
    path: '/messages',
    icon: 'fa fa-comment-sms'
  },
  {
    text: 'Tasks',
    path: '/tasks',
    icon: 'fa fa-list-check'
  },
];
