import React from "react";

export default function Privacy() {
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Privacy policy</h2>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'}>
                    Privacy policy
                </div>
            </div>
        </React.Fragment>
    )
}
