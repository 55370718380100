import React, {useCallback, useEffect, useMemo, useState} from "react";
import TextBox from "devextreme-react/text-box";
import {CheckBox, FileUploader, TextArea, Toast} from "devextreme-react";
import {Popup, ToolbarItem} from "devextreme-react/popup";

export const EditMessage = (props) => {
    const {
        hideInfo,
        popupVisible,
        messageDetail,
        messages,
        setMessages,
    } = props;
    const [messageValue, setMessageValue] = useState('');
    const [disablePreviewPage, setDisablePreviewPage] = useState(false);
    const [disableSendingMessage, setDisableSendingMessage] = useState(false);
    const [textValue, setTextValue] = useState('');
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [messageType, setMessageType] = useState(undefined);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    const onDisablePreviewPageCheckboxValueChanged = useCallback((args) => {
        setDisablePreviewPage(args.value);
    }, []);

    const onDisableSendingMessageCheckboxValueChanged = useCallback((args) => {
        setDisableSendingMessage(args.value);
    }, []);

    const onMessageValueChange = useCallback((e) => {
        setMessageValue(e.value);
    }, [setMessageValue]);

    const onTextValueChanged = useCallback((e) => {
        setTextValue(e.value);
    }, []);

    const onSelectedFilesChanged = useCallback(
        (e) => {
            setSelectedFiles(e.value);
        },
        [setSelectedFiles],
    );
    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    const toastErrorFillGaps = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: "error",
            message: `Please fill all gaps!`,
        });
    }

    const saveOptions = useMemo(() => {
        return {
            type: 'success',
            stylingMode: 'contained',
            text:'Save',
            onClick: () => {
                if (!messageValue || !selectedFiles || !textValue) {
                    toastErrorFillGaps();
                    return;
                }

                if (messageType === 2 || messageType === 3 || messageType === 4) {
                    if (!selectedFiles || !selectedFiles.length) {
                        toastErrorFillGaps();
                        return;
                    }
                }

                const filteredMessages = [...messages].filter((message) => message.id !== messageDetail.id);
                const editedMessage = {
                    id: messageDetail.id,
                    messageName: messageValue,
                    typeOfMessageId: messageType,
                    disablePreviewPage,
                    disableSendingMessage,
                    text: textValue,
                    files: messageType === 2 || messageType === 3 || messageType === 4 ? selectedFiles : null,
                }
                setMessages([...filteredMessages, editedMessage].sort((a, b) => a.id - b.id));
                hideInfo();
            },
        }
    }, [messages, messageValue, messageType, disablePreviewPage, disableSendingMessage, textValue, selectedFiles]);
    const cancelOptions = useMemo(() => {
        return {
            type: 'danger',
            stylingMode: 'contained',
            text:'Cancel',
            onClick: ()=>{
                hideInfo();
            },
        }
    }, []);

    useEffect(() => {
        if (messageDetail) {
            setMessageValue(messageDetail.messageName);
            setDisablePreviewPage(messageDetail.disablePreviewPage);
            setDisableSendingMessage(messageDetail.disableSendingMessage);
            setTextValue(messageDetail.text);
            setSelectedFiles(messageDetail.files);
            setMessageType(messageDetail.typeOfMessageId);
        }
    }, [messageDetail, popupVisible]);

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={'Edit message'}
                container=".dx-viewport"
                width={700}
                height={525}
            >
                <TextBox
                    value={messageValue}
                    onValueChanged={onMessageValueChange}
                    className={'add-message-input'}
                    label={'Message name'}
                />

                <div className={'edit-message-checkboxes'}>
                    <CheckBox
                        value={disablePreviewPage}
                        onValueChanged={onDisablePreviewPageCheckboxValueChanged}
                        text={`Запретить предпросмотр веб-страницы`}
                    />

                    <CheckBox
                        value={disableSendingMessage}
                        onValueChanged={onDisableSendingMessageCheckboxValueChanged}
                        text={`Запретить отправку уведомления`}
                    />
                </div>

                {messageType && messageType === 1 && (
                    <TextArea
                        height={100}
                        value={textValue}
                        onValueChanged={onTextValueChanged}
                        label={'Message text'}
                    />
                )}

                {messageType && messageType === 2 && (
                    <>
                        <TextArea
                            height={100}
                            value={textValue}
                            onValueChanged={onTextValueChanged}
                            label={'The text of the caption to the picture'}
                        />

                        <FileUploader
                            multiple={false}
                            accept={'image/png, image/gif, image/jpeg'}
                            uploadMode={'instantly'}
                            onValueChanged={onSelectedFilesChanged}
                            selectButtonText="Select picture"
                            className={'add-message-uploader'}
                            value={selectedFiles}
                        />
                    </>
                )}

                {messageType && messageType === 3 && (
                    <>
                        <TextArea
                            height={100}
                            value={textValue}
                            onValueChanged={onTextValueChanged}
                            label={'The text of the caption to the video'}
                        />

                        <FileUploader
                            multiple={false}
                            accept={'video/mp4, video/x-m4v, video/*'}
                            uploadMode={'instantly'}
                            onValueChanged={onSelectedFilesChanged}
                            selectButtonText="Select video"
                            className={'add-message-uploader'}
                            value={selectedFiles}
                        />
                    </>
                )}

                {messageType && messageType === 4 && (
                    <>
                        <TextArea
                            height={100}
                            value={textValue}
                            onValueChanged={onTextValueChanged}
                            label={'The text of the caption to the audio'}
                        />

                        <FileUploader
                            multiple={false}
                            accept={'audio/*'}
                            uploadMode={'instantly'}
                            onValueChanged={onSelectedFilesChanged}
                            selectButtonText="Load voice audio"
                            className={'add-message-uploader'}
                            value={selectedFiles}
                        />
                    </>
                )}

                {messageType && messageType === 5 && (
                    <TextArea
                        height={100}
                        value={textValue}
                        onValueChanged={onTextValueChanged}
                        label={'Links to messages'}
                    />
                )}

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={saveOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={cancelOptions}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1000}
                position={"top"}
            />
        </>
    )
}
