import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, FileUploader, NumberBox, RadioGroup, SelectBox, Toast} from "devextreme-react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import './EditAccount.scss';
import {accountGendersDemo, accountImageUploadTypeDemo, accountsStatusesDemo} from "../../../../utils/demo-data";

export const EditAccount = (props) => {
    const {
        popupVisible,
        hideInfo,
        groupsDataSource,
        customItemCreating,
        editAccountDetail,
        accounts,
        setAccounts,
    } = props;
    const [group, setGroup] = useState('');
    const [telegramId, setTelegramId] = useState();
    const [phone, setPhone] = useState('');
    const [telegramClient, setTelegramClient] = useState('');
    const [messagesSent, setMessagesSent] = useState();
    const [status, setStatus] = useState('');
    const [gender, setGender] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [imageDetail, setImageDetail] = useState('');
    const [imageUploadType, setImageUploadType] = useState('');
    const [imageUploadDisabled, setImageUploadDisabled] = useState(true);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    const onGroupChange = useCallback((e) => {
        setGroup(e.value);
    }, [setGroup]);

    const onGenderChange = useCallback((e) => {
        setGender(e.value);
    }, [setGender]);

    const onFirstNameChange = useCallback((e) => {
        setFirstName(e.value);
    }, [setFirstName]);

    const onLastNameChange = useCallback((e) => {
        setLastName(e.value);
    }, [setLastName]);

    const onSelectedFilesChanged = (e) => {
        const file = e.value[0];

        const reader = new FileReader();
        reader.onloadend = () => {
            setImageDetail(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const onDeleteAvatar = () => {
        setImageDetail('');
    }

    const changeImageUploadType = (e) => {
        setImageUploadType(e.value);
    };

    const clearValues = () => {
        setGroup(undefined);
        setTelegramId(undefined);
        setPhone('');
        setTelegramClient('');
        setMessagesSent(undefined);
        setStatus(undefined);
        setGender(undefined);
        setFirstName('');
        setLastName('');
        setImageDetail('');
        setImageUploadType(undefined);
    }

    const saveOptions = useMemo(() => {
        return {
            type: 'success',
            stylingMode: 'contained',
            text:'Save',
            onClick: () => {
                if (!firstName || !lastName) {
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: "error",
                        message: `Please fill all the gaps!`,
                    });
                    return;
                }

                const editedAccount = {
                    id: editAccountDetail.id,
                    groupId: group,
                    telegramId: telegramId,
                    phone: phone,
                    firstName: firstName,
                    lastName: lastName,
                    genderId: gender,
                    avatar: imageDetail,
                    telegramClient: telegramClient,
                    messagesSent: messagesSent,
                    statusId: status,
                }
                const filteredAccounts = [...accounts].filter((account) => account.id !== editAccountDetail.id);
                setAccounts(
                    [...filteredAccounts, editedAccount].sort((a, b) => a.id - b.id)
                );
                hideInfo();
            },
        }
    }, [
        editAccountDetail,
        group,
        telegramId,
        phone,
        firstName,
        lastName,
        gender,
        imageDetail,
        telegramClient,
        messagesSent,
        status
    ]);
    const cancelOptions = useMemo(() => {
        return {
            type: 'danger',
            stylingMode: 'contained',
            text:'Cancel',
            onClick: ()=>{
                clearValues();
                hideInfo();
            },
        }
    }, []);

    useEffect(() => {
        if (editAccountDetail) {
            setGroup(editAccountDetail.groupId);
            setTelegramId(editAccountDetail.telegramId);
            setPhone(editAccountDetail.phone);
            setTelegramClient(editAccountDetail.telegramClient);
            setMessagesSent(editAccountDetail.messagesSent);
            setStatus(editAccountDetail.statusId);
            setGender(editAccountDetail.genderId);
            setFirstName(editAccountDetail.firstName);
            setLastName(editAccountDetail.lastName);
            setImageDetail(editAccountDetail.avatar);
        }
    }, [editAccountDetail, popupVisible])

    useEffect(() => {
        if (imageUploadType) {
            setImageUploadDisabled(false);
        } else {
            setImageUploadDisabled(true);
        }
    }, [imageUploadType, setImageUploadDisabled])

    if (!editAccountDetail) {
        return <></>;
    }

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Edit Account"
                container=".dx-viewport"
                width={700}
                height={600}
            >
                <div className={'edit-account-modal'}>
                    <div className={'edit-account-wrapper'}>
                        <SelectBox
                            items={groupsDataSource.items()}
                            label={'Groups'}
                            displayExpr="name"
                            valueExpr="id"
                            value={group}
                            onValueChanged={onGroupChange}
                            className={'edit-account-item'}
                            acceptCustomValue={true}
                            onCustomItemCreating={customItemCreating}
                        />
                        <NumberBox
                            label={'Telegram Id'}
                            value={telegramId}
                            className={'edit-account-item'}
                            disabled={true}
                        />
                    </div>

                    <div className={'edit-account-wrapper'}>
                        <TextBox
                            label={'Phone'}
                            value={phone}
                            className={'edit-account-item'}
                            disabled={true}
                        />
                        <TextBox
                            label={'Telegram Client'}
                            value={telegramClient}
                            className={'edit-account-item'}
                            disabled={true}
                        />
                    </div>

                    <div className={'edit-account-wrapper'}>
                        <NumberBox
                            label={'Messages Sent'}
                            value={messagesSent}
                            className={'edit-account-item'}
                            disabled={true}
                        />
                        <SelectBox
                            items={accountsStatusesDemo}
                            label={'Status'}
                            displayExpr="name"
                            valueExpr="id"
                            value={status}
                            className={'edit-account-item'}
                            disabled={true}
                        />
                    </div>

                    <div className={'edit-account-wrapper'}>
                        <SelectBox
                            items={accountGendersDemo}
                            label={'Gender'}
                            displayExpr="name"
                            valueExpr="id"
                            value={gender}
                            onValueChanged={onGenderChange}
                            className={'edit-account-item'}
                        />
                        <TextBox
                            label={'First Name'}
                            value={firstName}
                            onValueChanged={onFirstNameChange}
                            className={'edit-account-item'}
                        />
                    </div>

                    <div className={'edit-account-wrapper'}>
                        <TextBox
                            label={'Last Name'}
                            value={lastName}
                            onValueChanged={onLastNameChange}
                            className={'edit-account-item'}
                        />
                    </div>

                    <div className={'edit-account-wrapper'}>
                        <div className={'avatar-wrapper'}>
                            <div className={'avatar-image-block'}>
                                {
                                    imageDetail
                                        ? (
                                            <img
                                                src={imageDetail}
                                                alt={`${firstName}-${lastName}-${imageDetail}-avatar`}
                                            />
                                        )
                                        : (
                                            <div className={'no-avatar-text'}>
                                                No userpic
                                            </div>
                                        )
                                }

                            </div>
                            <div className={'avatar-actions'}>
                                {imageDetail && (
                                    <Button
                                        type="danger"
                                        className={'delete-avatar-button'}
                                        onClick={onDeleteAvatar}
                                    >
                                        Delete image
                                    </Button>
                                )}

                                <div className={'account-image-radiobutton-block'}>
                                    <div className={'account-image-radiobutton-title'}>Upload image:</div>
                                    <RadioGroup
                                        items={accountImageUploadTypeDemo}
                                        value={imageUploadType}
                                        valueExpr="id"
                                        displayExpr="name"
                                        onValueChanged={changeImageUploadType}
                                    />
                                </div>

                                {popupVisible && (
                                    <FileUploader
                                        multiple={false}
                                        accept={'image/png, image/gif, image/jpeg'}
                                        uploadMode={'instantly'}
                                        onValueChanged={onSelectedFilesChanged}
                                        selectButtonText="Select new photo"
                                        className={'account-new-photo-uploader'}
                                        disabled={imageUploadDisabled}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={saveOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={cancelOptions}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1000}
                position={"top"}
            />
        </>
    )
}
