import {
    AccountsPage,
    AudiencesPage,
    HomePage,
    MessagesPage, PrivacyPage,
    ProfilePage,
    ProxiesPage,
    TasksPage,
    TelegramClientsPage
} from './pages';
import {withNavigationWatcher} from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
  {
    path: '/proxies',
    element: ProxiesPage
  },
    {
        path: '/audiences',
        element: AudiencesPage,
    },
    {
        path: '/telegram-clients',
        element: TelegramClientsPage,
    },
  {
    path: '/accounts',
    element: AccountsPage
  },
  {
    path: '/messages',
    element: MessagesPage
  },
    {
        path: '/privacy',
        element: PrivacyPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
