import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import {CheckBox, NumberBox, SelectBox, Toast} from "devextreme-react";
import TextBox from "devextreme-react/text-box";
import {proxiesGroupsDemo} from "../../../../utils/demo-data";
import './EditGroup.scss';

export const EditGroup = ({popupVisible, hideInfo, groupDetail, groupsDataSource}) => {
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });
    const [groupName, setGroupName] = useState();
    const [proxiesGroup, setProxiesGroup] = useState();
    const [sendLimit, setSendLimit] = useState();
    const [sendLimitFrame, setSendLimitFrame] = useState();
    const [sleepTime, setSleepTime] = useState();
    const [peerFloodTimeout, setPeerFloodTimeout] = useState();
    const [peerFloodLimit, setPeerFloodLimit] = useState();
    const [peerFloodMultiplier, setPeerFloodMultiplier] = useState();
    const [peerFloodMaxTries, setPeerFloodMaxTries] = useState();
    const [ignoreCapacity, setIgnoreCapacity] = useState();
    const [ignorePeerFlood, setIgnorePeerFlood] = useState();

    const onGroupNameValueChange = useCallback((e) => {
        setGroupName(e.value);
    }, [setGroupName]);

    const onProxiesChange = useCallback((e) => {
        setProxiesGroup(e.value);
    }, [setProxiesGroup]);

    const onSendLimitValueChange = useCallback((e) => {
        setSendLimit(e.value);
    }, [setSendLimit]);

    const onSendLimitFrameValueChange = useCallback((e) => {
        setSendLimitFrame(e.value);
    }, [setSendLimitFrame]);

    const onSleepTimeValueChange = useCallback((e) => {
        setSleepTime(e.value);
    }, [setSleepTime]);

    const onPeerFloodTimeoutValueChange = useCallback((e) => {
        setPeerFloodTimeout(e.value);
    }, [setPeerFloodTimeout]);

    const onPeerFloodLimitValueChange = useCallback((e) => {
        setPeerFloodLimit(e.value);
    }, [setPeerFloodLimit]);

    const onPeerFloodMultiplierValueChange = useCallback((e) => {
        setPeerFloodMultiplier(e.value);
    }, [setPeerFloodMultiplier]);

    const onPeerFloodMaxTriesValueChange = useCallback((e) => {
        setPeerFloodMaxTries(e.value);
    }, [setPeerFloodMaxTries]);

    const onIgnoreCapacityValueChange = useCallback((e) => {
        setIgnoreCapacity(e.value);
    }, [setIgnoreCapacity]);

    const onIgnorePeerFloodValueChange = useCallback((e) => {
        setIgnorePeerFlood(e.value);
    }, [setIgnorePeerFlood]);

    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    const saveOptions = useMemo(() => {
        return {
            type: 'success',
            stylingMode: 'contained',
            text:'Save',
            onClick: () => {
                if (
                    !groupName || !sendLimit || !setSendLimitFrame ||
                    !sleepTime || !peerFloodTimeout || !peerFloodLimit || !peerFloodMultiplier ||
                    !peerFloodMaxTries
                ) {
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: "error",
                        message: `Please fill all the gaps!`,
                    });
                    return;
                }
                groupsDataSource.store().update(groupDetail.id, {
                    name: groupName,
                    proxiesGroups: proxiesGroup,
                    sendLimit,
                    sendLimitFrame,
                    sleepTime,
                    peerFloodTimeout,
                    peerFloodLimit,
                    peerFloodMultiplier,
                    peerFloodMaxTries,
                    ignoreCapacity,
                    ignorePeerFlood,
                })
                hideInfo();
            },
        }
    }, [
        groupDetail,
        groupName,
        proxiesGroup,
        sendLimit,
        sendLimitFrame,
        sleepTime,
        peerFloodTimeout,
        peerFloodLimit,
        peerFloodMultiplier,
        peerFloodMaxTries,
        ignoreCapacity,
        ignorePeerFlood,
    ]);
    const cancelOptions = useMemo(() => {
        return {
            type: 'danger',
            stylingMode: 'contained',
            text:'Cancel',
            onClick: ()=>{
                hideInfo();
            },
        }
    }, []);

    useEffect(() => {
        if (groupDetail) {
            setGroupName(groupDetail.name);
            setProxiesGroup(groupDetail.proxiesGroups);
            setSendLimit(groupDetail.sendLimit);
            setSendLimitFrame(groupDetail.sendLimitFrame);
            setSleepTime(groupDetail.sleepTime);
            setPeerFloodTimeout(groupDetail.peerFloodTimeout);
            setPeerFloodLimit(groupDetail.peerFloodLimit);
            setPeerFloodMultiplier(groupDetail.peerFloodMultiplier);
            setPeerFloodMaxTries(groupDetail.peerFloodMaxTries);
            setIgnoreCapacity(groupDetail.ignoreCapacity);
            setIgnorePeerFlood(groupDetail.ignorePeerFlood);
        }
    }, [groupDetail, popupVisible]);

    if (!groupDetail) {
        return <></>;
    }

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Edit group"
                container=".dx-viewport"
                width={700}
                height={600}
            >
                <TextBox
                    value={groupName}
                    onValueChanged={onGroupNameValueChange}
                    className={'edit-account-group-item'}
                    label={'Accounts group name'}
                />

                <SelectBox
                    items={proxiesGroupsDemo}
                    label={'Proxies groups'}
                    displayExpr="name"
                    valueExpr="id"
                    value={proxiesGroup}
                    onValueChanged={onProxiesChange}
                    className={'edit-account-group-item'}
                />

                <NumberBox
                    value={sendLimit}
                    onValueChanged={onSendLimitValueChange}
                    className={'edit-account-group-item'}
                    label={'Send limit'}
                />

                <NumberBox
                    value={sendLimitFrame}
                    onValueChanged={onSendLimitFrameValueChange}
                    className={'edit-account-group-item'}
                    label={'Send limit frame'}
                />

                <NumberBox
                    value={sleepTime}
                    onValueChanged={onSleepTimeValueChange}
                    className={'edit-account-group-item'}
                    label={'Sleep time'}
                />

                <NumberBox
                    value={peerFloodTimeout}
                    onValueChanged={onPeerFloodTimeoutValueChange}
                    className={'edit-account-group-item'}
                    label={'Peer flood timeout'}
                />

                <NumberBox
                    value={peerFloodLimit}
                    onValueChanged={onPeerFloodLimitValueChange}
                    className={'edit-account-group-item'}
                    label={'Peer flood limit'}
                />

                <NumberBox
                    value={peerFloodMultiplier}
                    onValueChanged={onPeerFloodMultiplierValueChange}
                    className={'edit-account-group-item'}
                    label={'Peer flood multiplier'}
                />

                <NumberBox
                    value={peerFloodMaxTries}
                    onValueChanged={onPeerFloodMaxTriesValueChange}
                    className={'edit-account-group-item'}
                    label={'Peer flood max tries'}
                />

                <div className={'edit-account-group-checkboxes'}>
                    <CheckBox
                        value={ignoreCapacity}
                        onValueChanged={onIgnoreCapacityValueChange}
                        text={`Ignore capacity`}
                    />

                    <CheckBox
                        value={ignorePeerFlood}
                        onValueChanged={onIgnorePeerFloodValueChange}
                        text={`Ignore peer flood`}
                    />
                </div>

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    // disabled={importButtonDisabled}
                    options={saveOptions}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={cancelOptions}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1000}
                position={"top"}
            />
        </>
    )
}
