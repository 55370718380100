import React from "react";
import DataGrid, {
    Button as GridButton,
    Column,
    Editing,
    Form,
    HeaderFilter,
    Item, Lookup,
    Pager,
    Paging,
    Popup, RequiredRule, Selection, Toolbar,
    ToolbarItem
} from "devextreme-react/data-grid";
import {audiencesTitleDemo, telegramClientsDemo} from "../../utils/demo-data";
import {Button} from "devextreme-react";

export default function TelegramClients() {
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Telegram Clients</h2>
            <div className={'content-block'}>
                <DataGrid
                    id={'telegramClientsGrid'}
                    className={'dx-card wide-card'}
                    dataSource={telegramClientsDemo}
                    keyExpr={"id"}
                    showBorders={false}
                    focusedRowEnabled={false}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                >
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} />
                    <Editing
                        allowAdding={false}
                        allowUpdating={false}
                        allowDeleting={false}

                    />
                    <HeaderFilter visible={true} />

                    <Column dataField='name' allowEditing={false} />
                    <Column dataField='apiId' allowEditing={false} />
                    <Column dataField='apiHash' allowEditing={false} />

                </DataGrid>
            </div>
        </React.Fragment>
    )
}
