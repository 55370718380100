import './proxies.scss';

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {proxiesGroupsDemo, proxiesDemo} from '../../utils/demo-data';
import DataGrid, {
  Button as GridButton,
  Column, CustomRule,
  Editing,
  Form,
  GroupPanel,
  HeaderFilter,
  Item,
  Lookup, NumericRule,
  Pager,
  Paging,
  Popup,
  RequiredRule,
  Selection,
  Toolbar,
  ToolbarItem, ValidationRule,
  ValueFormat,
} from 'devextreme-react/data-grid';
import {Button, SelectBox, Toast} from 'devextreme-react';
import {confirm} from 'devextreme/ui/dialog';
import DataSource from "devextreme/data/data_source";
import LoadPanel from "devextreme-react/load-panel";
import {ImportProxy} from "../../components/modals/import-proxy/ImportProxy";

const groupsDataSource = new DataSource({
  store: {
    data: proxiesGroupsDemo,
    type: 'array',
    key: 'id',
  },
});

const customItemCreating = (args) => {
  if (!args.text) {
    args.customItem = null;
    return;
  }

  const groupsIds = proxiesGroupsDemo.map((item) => item.id);
  const incrementedId = Math.max.apply(null, groupsIds) + 1;
  const newItem = {
    id: incrementedId,
    name: args.text,
  };

  args.customItem = groupsDataSource
      .store()
      .insert(newItem)
      .then(() => groupsDataSource.load())
      .then(() => newItem)
      .catch((error) => {
        throw error;
      });
};
const groupsEditorRender = (cell) => {
  const onValueChanged = (e) => cell.setValue(e.value);

  return (
      <SelectBox
          defaultValue={cell.value}
          {...cell.column.lookup}
          onValueChanged={onValueChanged}
          acceptCustomValue={true}
          onCustomItemCreating={customItemCreating}
      />
  );
};

const loaderPosition = { of: '#proxies' };
export default function Proxies() {
  const [loadPanelVisible, setLoadPanelVisible] = useState(true);
  const [proxies, setProxies] = useState(proxiesDemo);
  const [selectedProxies, setSelectedProxies] = useState([])
  const dataGridRef = useRef(null);
  const [editPopupMode, setEditPopupMode] = useState(false);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'success',
    message: '',
  });

  const onHidingToast = useCallback(() => {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }, [toastConfig, setToastConfig]);

  const updateStatus = useMemo(() => {
    return {
      type: 'default',
      stylingMode: 'contained',
      text:'Update status',
      onClick: (e) => {
        console.log('updated status')
      },
    }
  }, [])
  const saveOptions = useMemo(() => {
    return {
      type: 'success',
      stylingMode: 'contained',
      text:'Save',
      onClick: () => {
        dataGridRef.current.instance.saveEditData();
        setLoadPanelVisible(true);
        setTimeout(() => {
          groupsDataSource.reload()
              .then(() => {
                setLoadPanelVisible(false);
              })
              .catch((err) => {
                setLoadPanelVisible(false);
                console.log(err);
              })
        }, 0)
      },
    }
  }, []);
  const cancelOptions = useMemo(() => {
    return {
      type: 'danger',
      stylingMode: 'contained',
      text:'Cancel',
      onClick: (e)=>{
        dataGridRef.current.instance.cancelEditData();
        setEditPopupMode(false);
      },
    }
  }, []);
  const handleSelectionChanged = (e) => {
    setSelectedProxies(e.selectedRowKeys)
  };

  const deleteProxies = useCallback(async () => {
    const confirmed = await confirm('Do you confirm deleting proxies?', 'Are you sure?');

    if (confirmed) {
      selectedProxies.forEach((proxyId) => {
        setProxies(prevState => prevState.filter((proxy) => proxy.id !== proxyId));
      })
      dataGridRef.current.instance.deselectAll();
    }
  }, [dataGridRef, selectedProxies]);

  const addProxy = useCallback(() => {
    dataGridRef.current.instance.addRow();
    dataGridRef.current.instance.deselectAll();
  }, [dataGridRef]);

  const onEditingStart = useCallback((e) => {
    setEditPopupMode(true);
  }, []);

  const showImportModal = useCallback(() => {
    setImportModalVisible(true);
  }, [setImportModalVisible])

  const closeImportModal = useCallback(() => {
    setImportModalVisible(false);
  }, [setImportModalVisible])

  const onAllProxiesCheckHandler = useCallback(() => {
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      type: "success",
      message: `Success! All proxies was checked now`,
    });
  }, [setToastConfig])

  const onReloadProxyHandler = useCallback(() => {
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      type: "success",
      message: `Proxy info was updated`,
    });
  }, [setToastConfig])

  const validatePort = useCallback((e) => {
    return e.value >= 0 && e.value <= 65535;
  }, []);

  useEffect(() => {
    groupsDataSource.load()
        .then((res) => {
          setLoadPanelVisible(false);
        })
        .catch((err) => {
          setLoadPanelVisible(false);
          console.log(err);
        })
  }, [groupsDataSource]);

  let content;

  if (loadPanelVisible) {
    content = (
        <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={loaderPosition}
            visible={loadPanelVisible}
            showIndicator={true}
            shading={true}
            showPane={true}
        />
    )
  } else {
    content = (
        <DataGrid
            id={'proxiesGrid'}
            ref={dataGridRef}
            className={'dx-card wide-card'}
            dataSource={proxies}
            keyExpr={"id"}
            showBorders={false}
            focusedRowEnabled={false}
            defaultFocusedRowIndex={0}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            onSelectionChanged={handleSelectionChanged}
            onEditingStart={onEditingStart}
        >
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <Editing
              mode="popup"
              allowUpdating={true}
              allowAdding={true}
              allowDeleting={true}>
            <Popup
                title="Add/Edit Proxy"
                showTitle={true}
                width={700}
                height={525}
            >
              {editPopupMode && (
                  <ToolbarItem
                      toolbar="bottom"
                      widget="dxButton"
                      location="after"
                      options={updateStatus}>
                  </ToolbarItem>
              )}
              <ToolbarItem
                  toolbar="bottom"
                  widget="dxButton"
                  location="after"
                  options={saveOptions}>
              </ToolbarItem>
              <ToolbarItem
                  toolbar="bottom"
                  widget="dxButton"
                  location="after"
                  options={cancelOptions}>
              </ToolbarItem>
            </Popup>
            <Form>
              <Item itemType="group" colCount={2} colSpan={2}>
                <Item
                    dataField="groupId"
                    caption="Group Name"
                />
                <Item dataField="host" />
                <Item
                    dataField="port"
                />
                <Item dataField="login" />
                <Item dataField="password"  />
              </Item>
              {editPopupMode && (
                  <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="status" disabled={true} />
                    <Item dataField="addedDate" disabled={true} />
                    <Item dataField="updatedDate" disabled={true} />
                    <Item dataField="statusUpdatedDate" disabled={true} />
                  </Item>
              )}
            </Form>
          </Editing>
          <HeaderFilter visible={true} />

          <Column
              dataField="groupId"
              caption="Group"
              groupIndex={0}
              editCellRender={groupsEditorRender}
          >
            <Lookup
                dataSource={groupsDataSource.items()}
                displayExpr="name"
                valueExpr="id"
            />
          </Column>
          <Column dataField='host'>
            <RequiredRule />
          </Column>
          <Column
              dataField='port'
              dataType="number"
          >
            <RequiredRule />
            <CustomRule
              message={'Please write true port (0-65535)'}
              validationCallback={validatePort}
            />
          </Column>
          <Column dataField='login'>
            <RequiredRule />
          </Column>
          <Column dataField='password'>
            <RequiredRule />
          </Column>
          <Column dataField='status'>
          </Column>
          <Column dataField='addedDate' visible={false}>
          </Column>
          <Column dataField='updatedDate' visible={false}>
          </Column>
          <Column dataField='statusUpdatedDate' visible={false}>
          </Column>
          <Column type='buttons'>
            <GridButton name='edit' />
            <GridButton name='delete' />
            <GridButton
                icon='fa-solid fa-repeat'
                onClick={onReloadProxyHandler}
            />
          </Column>

          <GroupPanel visible={true} />
          <Selection mode="multiple" />
          <Toolbar>
            <Item name="groupPanel" />
            <Item>
              <Button
                  text={"Add proxy"}
                  icon='fa fa-plus'
                  onClick={addProxy}
              />
            </Item>
            <Item>
              <Button
                  text={"Import proxies"}
                  icon='fa fa-file-import'
                  onClick={showImportModal}
              />
            </Item>
            <Item>
              <Button
                  text={"Check"}
                  icon='fa-solid fa-repeat'
                  disabled={selectedProxies.length === 0}
                  onClick={onAllProxiesCheckHandler}
              />
            </Item>
            <Item>
              <Button
                  text={"Delete"}
                  icon='fa fa-trash'
                  type="danger"
                  disabled={selectedProxies.length === 0}
                  onClick={deleteProxies}
              />
            </Item>
          </Toolbar>
        </DataGrid>
    )
  }

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Proxies</h2>
      <div className={'content-block'} id={'proxies'}>
        {content}
      </div>

      <ImportProxy
        popupVisible={importModalVisible}
        hideInfo={closeImportModal}
        items={proxiesGroupsDemo}
      />

      <Toast
          visible={toastConfig.isVisible}
          message={toastConfig.message}
          type={toastConfig.type}
          onHiding={onHidingToast}
          displayTime={1000}
          position={"top"}
      />
    </React.Fragment>
  )
}
