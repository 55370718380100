import React, {useCallback, useEffect, useState} from "react";
import {CheckBox, Toast} from "devextreme-react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import './WarningForCheck.scss';

export const WarningForCheck = ({popupVisible, hideInfo}) => {
    const [checkButtonDisabled, setCheckButtonDisabled] = useState(true);
    const [sureValue, setSureValue] = useState('');
    const [checkboxValue, setCheckboxValue] = useState(false);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });
    const onCheckFiles = () => {
        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: "success",
            message: `Successful checked`,
        });
        hideInfo();
    }

    const getCancelButtonOptions = useCallback(() => ({
        text: 'Cancel',
        stylingMode: 'contained',
        type: 'danger',
        width: 120,
        onClick: hideInfo,
    }), [hideInfo]);

    const getCheckButtonOptions = useCallback(() => ({
        text: 'Check',
        stylingMode: 'contained',
        type: 'success',
        width: 120,
        onClick: onCheckFiles,
    }), [onCheckFiles]);

    const onSureValueChange = useCallback((e) => {
        setSureValue(e.value);
    }, [setSureValue]);

    const onCheckboxValueChanged = useCallback((args) => {
        setCheckboxValue(args.value);
    }, []);

    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    useEffect(() => {
        if (sureValue === `I'm sure`) {
            setCheckButtonDisabled(false);
        } else {
            setCheckButtonDisabled(true);
        }
    }, [sureValue])

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Warning for check"
                container=".dx-viewport"
                width={600}
                height={380}
            >
                <div className={'warning-for-check-text'}>
                    Внимание, проверка аккаунтов связана с риском их
                    блокировки серверами мессенджера Telegram!
                    В результате проверки будет получена актуальная
                    информация по статусу аккаунта: ok, floodwait,
                    spamblock, ban, peerflood, revoked. Вы уверены,
                    что хотите проверить все аккаунты?
                    Для продолжения введите в поле ниже <strong>“I'm sure”</strong>
                </div>

                <TextBox
                    value={sureValue}
                    onValueChanged={onSureValueChange}
                    className={'sure-input-warning-check'}
                />

                <CheckBox
                    value={checkboxValue}
                    onValueChanged={onCheckboxValueChanged}
                    text={`Больше не спрашивать при проверке одного аккаунта`}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={checkButtonDisabled}
                    options={getCheckButtonOptions()}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={getCancelButtonOptions()}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1000}
                position={"top"}
            />
        </>
    )
}
