import React, {useEffect} from 'react';

import './LoginForm.scss';
import {useNavigate} from "react-router-dom";


export default function LoginForm() {
  const navigate = useNavigate();

  useEffect(() => {
    const button = document.createElement('script')
    button.async = true
    button.src = 'https://telegram.org/js/telegram-widget.js?22'
    button.setAttribute('data-telegram-login', 'TeleEvolutionBot')
    button.setAttribute('data-size', 'large')
    button.setAttribute('data-radius', '20')
    button.setAttribute('data-onauth', 'onTelegramAuth(user)')

    document.querySelector('#telegram-widget-container').appendChild(button)

    window.onTelegramAuth = async function (user) {
      localStorage.setItem('auth_info', JSON.stringify(user));
      navigate('/home');
    }
  }, []);

  return (
      <div id="telegram-widget-container"></div>
  );
}
