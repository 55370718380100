import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import React, {useEffect} from 'react';
import {HashRouter as Router, useNavigate} from 'react-router-dom';
import './dx-styles.scss';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import notify from "devextreme/ui/notify";

function App() {
  const authInfo = JSON.parse(localStorage.getItem('auth_info'));
  const navigate = useNavigate();

  // if (loading) {
  //   return <LoadPanel visible={true} />;
  // }

  useEffect(() => {
    if (!authInfo) {
      navigate('/login');
      notify({
        message: 'You should to login',
        position: 'top center',
      }, 'warning', 2000)
    }

    if (authInfo && ((Date.now() / 1000 - authInfo.auth_date) > 86400)) {
      localStorage.removeItem('auth_info');
      navigate('/login');
    }
  }, [authInfo])

  if (authInfo) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
