import React, {useCallback, useEffect, useState} from "react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import {Button, FileUploader, SelectBox, Toast} from "devextreme-react";
import {accountGendersDemo, accountLanguagesDemo} from "../../../../utils/demo-data";
import './MassAccountEdit.scss';

export const MassAccountEdit = (props) => {
    const {
        popupVisible,
        hideInfo,
        dataSource,
        customItemCreating,
        accounts,
        setAccounts,
        selectedAccountsIds,
    } = props;
    const [languagesValue, setLanguagesValue] = useState();
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    const onSaveHandler = () => {
        const filteredAccounts = [...accounts].filter(account => !selectedAccountsIds.includes(account.id));
        setAccounts(
            [...filteredAccounts, ...selectedAccounts].sort((a, b) => a.id - b.id)
        )
        hideInfo();
    }

    const getCancelButtonOptions = useCallback(() => ({
        text: 'Cancel',
        stylingMode: 'contained',
        type: 'danger',
        width: 120,
        onClick: hideInfo,
    }), [hideInfo]);

    const getSaveButtonOptions = useCallback(() => ({
        text: 'Save',
        stylingMode: 'contained',
        type: 'success',
        width: 120,
        onClick: onSaveHandler,
    }), [onSaveHandler]);

    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    const groupsValueChanged = useCallback(({ component }) => {
        setSelectedAccounts(prevState => prevState.map((account) => (
            {
                ...account,
                groupId: component.option('selectedItem').id
            }
        )));
    }, []);

    const gendersValueChanged = useCallback(({ component }) => {
        setSelectedAccounts(prevState => prevState.map((account) => (
            {
                ...account,
                genderId: component.option('selectedItem').id
            }
        )));
    }, []);

    const languagesValueChanged = useCallback(({ component }) => {
        setLanguagesValue(component.option('selectedItem'));
    }, []);

    const onSelectedFilesChanged = (e) => {
        const files = e.value;
        const newAvatars = [];
        const filesCount = files.length;

        if (filesCount >= selectedAccounts.length) {
            for (let i = 0; i < selectedAccounts.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                reader.onloadend = () => {
                    newAvatars.push({ ...selectedAccounts[i], avatar: reader.result });
                    if (newAvatars.length === selectedAccounts.length) {
                        setSelectedAccounts(newAvatars);
                    }
                };
                reader.readAsDataURL(file);
            }
        } else {
            for (let i = 0; i < selectedAccounts.length; i++) {
                const file = files[i % filesCount];
                const reader = new FileReader();
                reader.onloadend = () => {
                    newAvatars.push({ ...selectedAccounts[i], avatar: reader.result });
                    if (newAvatars.length === selectedAccounts.length) {
                        setSelectedAccounts(newAvatars);
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const deleteUserPics = useCallback(() => {
        setSelectedAccounts(prevState => prevState.map((account) => (
            {
                ...account,
                avatar: '',
            }
        )))
    }, [])

    useEffect(() => {
        if (popupVisible) {
            const needAccounts = [];
            selectedAccountsIds.forEach((accountId) => {
                accounts.forEach((account) => {
                    if (account.id === accountId) {
                        needAccounts.push(account);
                    }
                })
            });
            setSelectedAccounts(needAccounts);
        } else {
            setSelectedAccounts([]);
        }
    }, [popupVisible, selectedAccountsIds, accounts])

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Mass account edit"
                container=".dx-viewport"
                width={700}
                height={500}
            >
                <SelectBox
                    dataSource={dataSource}
                    displayExpr="name"
                    valueExpr="id"
                    acceptCustomValue={true}
                    onCustomItemCreating={customItemCreating}
                    onValueChanged={groupsValueChanged}
                    label={'Groups'}
                    className={'mass-account-edit-selectbox'}
                />

                <SelectBox
                    dataSource={accountGendersDemo}
                    displayExpr="name"
                    valueExpr="id"
                    onValueChanged={gendersValueChanged}
                    label={'Genders'}
                    className={'mass-account-edit-selectbox'}
                />

                <SelectBox
                    dataSource={accountLanguagesDemo}
                    displayExpr="name"
                    valueExpr="id"
                    onValueChanged={languagesValueChanged}
                    label={'Languages'}
                    className={'mass-account-edit-selectbox'}
                />

                <Button
                    type="danger"
                    className={'mass-account-edit-btn'}
                    onClick={deleteUserPics}
                >
                    Delete all userpics
                </Button>

                <FileUploader
                    multiple={true}
                    accept={'image/png, image/gif, image/jpeg'}
                    uploadMode={'instantly'}
                    onValueChanged={onSelectedFilesChanged}
                    selectButtonText="Select new photos"
                    className={'mass-account-edit-photo-uploader'}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={getSaveButtonOptions()}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={getCancelButtonOptions()}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1000}
                position={"top"}
            />
        </>
    )
}
