import React, {memo, useCallback, useEffect, useState} from "react";
import {Popup, ToolbarItem} from 'devextreme-react/popup';
import './ImportProxy.scss';
import {FileUploader, SelectBox, Toast} from "devextreme-react";
import TextBox from 'devextreme-react/text-box';
import DataSource from "devextreme/data/data_source";

export const ImportProxy = memo(({popupVisible, hideInfo, items, disabled, defaultValue}) => {
    const [editBoxValue, setEditBoxValue] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [importButtonDisabled, setImportButtonDisabled] = useState(true);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    const itemsDataSource = new DataSource({
        store: {
            data: items,
            type: 'array',
            key: 'id',
        },
    });

    const customItemCreating = (args) => {
        if (!args.text) {
            args.customItem = null;
            return;
        }

        const itemsIds = items.map((item) => item.id);
        const incrementedId = Math.max.apply(null, itemsIds) + 1;
        const newItem = {
            id: incrementedId,
            name: args.text,
        };

        args.customItem = itemsDataSource
            .store()
            .insert(newItem)
            .then(() => itemsDataSource.load())
            .then(() => newItem)
            .catch((error) => {
                throw error;
            });
    };

    const editBoxValueChanged = useCallback(({ component }) => {
        setEditBoxValue(component.option('selectedItem'));
    }, []);

    const onSelectedFilesChanged = useCallback(
        (e) => {
            setSelectedFile(e.value);
        },
        [setSelectedFile],
    );

    const onImportFile = useCallback(() => {
        if (editBoxValue && editBoxValue.name === 'error') {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: "error",
                message: `Error importing CSV file`,
            });
        } else {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: "success",
                message: `Successful import to ${defaultValue ? defaultValue : editBoxValue.name}`,
            });
        }

        hideInfo();
    }, [editBoxValue, defaultValue]);

    const getCancelButtonOptions = useCallback(() => ({
        text: 'Cancel',
        stylingMode: 'contained',
        type: 'danger',
        width: 120,
        onClick: hideInfo,
    }), [hideInfo]);

    const getImportButtonOptions = useCallback(() => ({
        text: 'Import',
        stylingMode: 'contained',
        type: 'success',
        width: 120,
        onClick: onImportFile,
    }), [onImportFile]);

    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    useEffect(() => {
        if (defaultValue) {
            if (!selectedFile) {
                setImportButtonDisabled(true);
            } else {
                setImportButtonDisabled(false);
            }
        } else {
            if (!selectedFile || !editBoxValue) {
                setImportButtonDisabled(true);
            } else {
                setImportButtonDisabled(false);
            }
        }
    }, [defaultValue, selectedFile, editBoxValue])

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Import proxy"
                container=".dx-viewport"
                width={600}
                height={350}
            >
                {!disabled && (
                    <SelectBox
                        dataSource={itemsDataSource}
                        displayExpr="name"
                        valueExpr="id"
                        acceptCustomValue={true}
                        onCustomItemCreating={customItemCreating}
                        onValueChanged={editBoxValueChanged}
                        disabled={disabled}
                    />
                )}

                {disabled && (
                    <TextBox
                        value={defaultValue}
                        disabled={disabled}
                    />
                )}

                <FileUploader
                    multiple={false}
                    accept={'text/csv'}
                    uploadMode={'instantly'}
                    onValueChanged={onSelectedFilesChanged}
                    selectButtonText="Select CSV file"
                    className={'import-uploader'}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={importButtonDisabled}
                    options={getImportButtonOptions()}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={getCancelButtonOptions()}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1000}
                position={"top"}
            />
        </>
    )
})
