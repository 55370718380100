import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import DataGrid, {
    Button as GridButton,
    Column,
    Editing,
    Form,
    HeaderFilter,
    Item,
    Lookup,
    Pager,
    Paging,
    Popup,
    RequiredRule,
    Selection,
    Toolbar,
    ToolbarItem
} from "devextreme-react/data-grid";
import {Button} from "devextreme-react";
import {audiencesDemo, audiencesTitleDemo} from "../../utils/demo-data";
import {ImportProxy} from "../../components/modals/import-proxy/ImportProxy";
import TextBox from "devextreme-react/text-box";
import {confirm} from "devextreme/ui/dialog";

const audienceTitleEditorRender = (cell) => {
    const selectedItemValueId = cell.value;
    const value = [...cell.column.lookup.dataSource]
        .filter((item) => item.id === selectedItemValueId)[0];

    const onValueChanged = (e) => {
        value.name = e.value;
    };

    return (
        <TextBox
            value={value.name}
            onValueChanged={onValueChanged}
        />
    );
};

export default function Audiences() {
    const dataGridRef = useRef(null);
    const [selectedAudiences, setSelectedAudiences] = useState([]);
    const [importModalVisible, setImportModalVisible] = useState(false);
    const [audiences, setAudiences] = useState([]);
    const [audienceDetailTitle, setAudienceDetailTitle] = useState('');
    const [audienceDetailContacts, setAudienceDetailContacts] = useState([]);
    const [importItemsEditingMode, setImportItemsEditingMode] = useState(false);

    const handleSelectionChanged = (e) => {
        setSelectedAudiences(e.selectedRowKeys)
    };

    const showImportModal = useCallback((e, editing) => {
        if (e.row) {
            const needTitle = [...audiencesTitleDemo]
                .filter((title) => title.id === e.row.data.audienceTitleId)[0];
            setAudienceDetailTitle(needTitle.name);
        }
        setImportItemsEditingMode(editing);
        setImportModalVisible(true);
    }, [setImportModalVisible])

    const closeImportModal = useCallback(() => {
        setImportModalVisible(false);
    }, [setImportModalVisible])

    const updateAudiences = () => {
        setAudiences(audiencesDemo.map((audience) => (
            {
                ...audience,
                contactsCount: audience.contacts.length,
            }
        )));
    };

    const saveOptions = useMemo(() => {
        return {
            type: 'success',
            stylingMode: 'contained',
            text:'Save',
            onClick: (e) => {
                dataGridRef.current.instance.saveEditData();
                updateAudiences();
            },
        }
    }, []);
    const cancelOptions = useMemo(() => {
        return {
            type: 'danger',
            stylingMode: 'contained',
            text:'Cancel',
            onClick: (e)=>{
                dataGridRef.current.instance.cancelEditData();
                updateAudiences();
            },
        }
    }, []);

    const onEditingStart = useCallback((e) => {
        setAudienceDetailContacts(e.data.contacts);
    }, []);

    const deleteAudiences = useCallback(async () => {
        const confirmed = await confirm('Do you confirm deleting audiences?', 'Are you sure?');

        if (confirmed) {
            selectedAudiences.forEach((audienceId) => {
                setAudiences(prevState => prevState.filter((audience) => audience.id !== audienceId));
            })
            dataGridRef.current.instance.deselectAll();
        }
    }, [dataGridRef, selectedAudiences]);

    useEffect(() => {
        setAudiences(audiencesDemo.map((audience) => (
            {
                ...audience,
                contactsCount: audience.contacts.length,
            }
        )))
    }, []);

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Audiences</h2>
            <div className={'content-block'} id={'audiences'}>
                <DataGrid
                    id={'audiencesGrid'}
                    ref={dataGridRef}
                    className={'dx-card wide-card'}
                    dataSource={audiences}
                    keyExpr={"id"}
                    showBorders={false}
                    focusedRowEnabled={false}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    onSelectionChanged={handleSelectionChanged}
                    onEditingStart={onEditingStart}
                >
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} />
                    <Editing
                        mode="popup"
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}>
                        <Popup
                            title="Edit Audience"
                            showTitle={true}
                            width={700}
                            height={525}
                        >
                            <ToolbarItem
                                toolbar="bottom"
                                widget="dxButton"
                                location="after"
                                options={saveOptions}
                            >
                            </ToolbarItem>
                            <ToolbarItem
                                toolbar="bottom"
                                widget="dxButton"
                                location="after"
                                options={cancelOptions}
                            >
                            </ToolbarItem>
                        </Popup>
                        <Form>
                            <Item itemType="group" colCount={1} colSpan={2}>
                                <Item
                                    dataField="audienceTitleId"
                                    caption="Audience"
                                />
                                <Item>
                                    <DataGrid
                                        dataSource={audienceDetailContacts}
                                        showBorders={true}
                                        columnAutoWidth={true}
                                    >
                                        <Editing
                                            allowUpdating={false}
                                            allowAdding={false}
                                            allowDeleting={true}
                                        />
                                        <Column dataField="name" />
                                    </DataGrid>
                                </Item>
                            </Item>
                        </Form>
                    </Editing>
                    <HeaderFilter visible={true} />

                    <Column
                        dataField="audienceTitleId"
                        caption="Audience"
                        editCellRender={audienceTitleEditorRender}
                    >
                        <RequiredRule />
                        <Lookup
                            dataSource={audiencesTitleDemo}
                            displayExpr="name"
                            valueExpr="id"
                        />
                    </Column>
                    <Column dataField='contactsCount' allowEditing={false}>
                        <RequiredRule />
                    </Column>
                    <Column type='buttons'>
                        <GridButton
                            icon='add'
                            onClick={(e) => { showImportModal(e, false); }}
                        />
                        <GridButton name='edit' />
                        <GridButton name='delete' />
                    </Column>

                    <Selection mode="multiple" />
                    <Toolbar>
                        <Item name="groupPanel" />
                        <Item>
                            <Button
                                text={"Import audiences"}
                                icon='fa fa-file-import'
                                onClick={(e) => { showImportModal(e, true); }}
                            />
                        </Item>
                        <Item>
                            <Button
                                text={"Delete"}
                                icon='fa fa-trash'
                                type="danger"
                                disabled={selectedAudiences.length === 0}
                                onClick={deleteAudiences}
                            />
                        </Item>
                    </Toolbar>
                </DataGrid>
            </div>

            <ImportProxy
                popupVisible={importModalVisible}
                hideInfo={closeImportModal}
                items={audiencesTitleDemo}
                disabled={!importItemsEditingMode}
                defaultValue={audienceDetailTitle}
            />
        </React.Fragment>
    )
}
