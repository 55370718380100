import React, {useCallback, useRef, useState} from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Button as GridButton,
  Column,
  Editing,
  HeaderFilter,
  Item,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  Selection,
  Toolbar
} from "devextreme-react/data-grid";
import {
  accountsGroupsDemo, audiencesDemo,
  audiencesTitleDemo,
  tasksDemo, tasksMessagesDemo,
  tasksNamesDemo, tasksResultsDemo,
  tasksStatusesDemo
} from "../../utils/demo-data";
import {Button} from "devextreme-react";
import {confirm} from "devextreme/ui/dialog";
import {EditTask} from "../../components/modals/tasks-page/edit-task/EditTask";
import {TaskStatistics} from "../../components/modals/tasks-page/task-statistics/TaskStatistics";
import {GenerateReport} from "../../components/modals/tasks-page/generate-report/GenerateReport";

const accountsGroupsCellRender = (data) => {
  const filteredAccountsGroups = [...accountsGroupsDemo].filter(group => data.value.includes(group.id));
  const needStrings = filteredAccountsGroups.map((group) => group.name);
  const finishString = needStrings.join(';');

  return (
      <div>{finishString}</div>
  )
};

const audiencesCellRender = (data) => {
  const filteredAudiences = [...audiencesTitleDemo].filter(audience => data.value.includes(audience.id));
  const needStrings = filteredAudiences.map((audience) => audience.name);
  const finishString = needStrings.join(';');

  return (
      <div>{finishString}</div>
  )
};

export default function Task() {
  const dataGridRef = useRef(null);
  const [tasks, setTasks] = useState(tasksDemo);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
  const [taskStatsModalVisible, setTaskStatsModalVisible] = useState(false);
  const [generateReportModalVisible, setGenerateReportModalVisible] = useState(false);
  const [editTaskDetail, setEditTaskDetail] = useState(undefined);
  const [taskStatsDetail, setTaskStatsDetail] = useState(undefined);

  const handleSelectionChanged = (e) => {
    setSelectedTasks(e.selectedRowKeys)
  };

  const deleteTasks = useCallback(async () => {
    const confirmed = await confirm('Do you confirm deleting tasks?', 'Are you sure?');

    if (confirmed) {
      selectedTasks.forEach((taskId) => {
        setTasks(prevState => prevState.filter((task) => task.id !== taskId));
      })
      dataGridRef.current.instance.deselectAll();
    }
  }, [dataGridRef, selectedTasks]);

  const openEditTaskModal = useCallback((e) => {
    if (e.row) {
      setEditTaskDetail(e.row.data);
    }
    setEditTaskModalVisible(true);
  }, [setEditTaskModalVisible])

  const closeEditTaskModal = useCallback(() => {
    setEditTaskModalVisible(false);
  }, [setEditTaskModalVisible])

  const openTaskStatsModal = useCallback((e) => {
    if (e.row) {
      setTaskStatsDetail(e.row.data);
    } else {
      const filteredTask = tasksDemo.filter((task) => task.id === selectedTasks[0])[0];
      setTaskStatsDetail(filteredTask);
    }
    setTaskStatsModalVisible(true);
  }, [setTaskStatsModalVisible, selectedTasks])

  const closeTaskStatsModal = useCallback(() => {
    setTaskStatsDetail(undefined);
    setTaskStatsModalVisible(false);
  }, [setTaskStatsModalVisible])

  const openGenerateReportModal = useCallback(() => {
    setGenerateReportModalVisible(true);
  }, [setGenerateReportModalVisible])

  const closeGenerateReportModal = useCallback(() => {
    setGenerateReportModalVisible(false);
  }, [setGenerateReportModalVisible])

  function isPlayButtonVisible (e) {
    return !e.row.data.started;
  }

  function isPauseButtonVisible (e) {
    return e.row.data.started;
  }

  const onStartTaskHandler = useCallback((e) => {
    const filteredTask = [...tasks].filter((task) => task.id === e.row.data.id)[0];
    filteredTask.started = true;
    const otherTasks = [...tasks].filter((task) => task.id !== e.row.data.id);

    setTasks([...otherTasks, filteredTask].sort((a, b) => a.id - b.id));
  }, [tasks])

  const onPauseTaskHandler = useCallback((e) => {
    const filteredTask = [...tasks].filter((task) => task.id === e.row.data.id)[0];
    filteredTask.started = false;
    const otherTasks = [...tasks].filter((task) => task.id !== e.row.data.id);

    setTasks([...otherTasks, filteredTask].sort((a, b) => a.id - b.id));
  }, [tasks])

  return (
      <React.Fragment>
        <h2 className={'content-block'}>Tasks</h2>
        <div className={'content-block'}>
          <DataGrid
              id={'messagesGrid'}
              ref={dataGridRef}
              className={'dx-card wide-card'}
              dataSource={tasks}
              keyExpr={"id"}
              showBorders={false}
              focusedRowEnabled={false}
              defaultFocusedRowIndex={0}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              onSelectionChanged={handleSelectionChanged}
          >
            <Paging defaultPageSize={10} />
            <Pager showPageSizeSelector={true} showInfo={true} />
            <Editing
                mode="popup"
                allowAdding={true}
                allowUpdating={true}
                allowDeleting={true}
            />
            <HeaderFilter visible={true} />

            <Column dataField='taskName'>
              <RequiredRule />
            </Column>
            <Column
                dataField="accountsGroupsIds"
                caption="Accounts groups"
                cellRender={accountsGroupsCellRender}
            />
            <Column
                dataField="audiencesIds"
                caption="Audiences"
                cellRender={audiencesCellRender}
            />
            <Column
                dataField="messageId"
                caption="Message"
            >
              <RequiredRule />
              <Lookup
                  dataSource={tasksMessagesDemo}
                  displayExpr="name"
                  valueExpr="id"
              />
            </Column>
            <Column
                dataField="statusId"
                caption="Status"
            >
              <RequiredRule />
              <Lookup
                  dataSource={tasksStatusesDemo}
                  displayExpr="name"
                  valueExpr="id"
              />
            </Column>
            <Column
                dataField="resultOfFinishingId"
                caption="Results"
            >
              <RequiredRule />
              <Lookup
                  dataSource={tasksResultsDemo}
                  displayExpr="name"
                  valueExpr="id"
              />
            </Column>

            <Column type='buttons'>
              <GridButton
                  name='edit'
                  onClick={openEditTaskModal}
              />
              <GridButton name='delete' />
              <GridButton
                  icon='fa fa-play'
                  visible={isPlayButtonVisible}
                  onClick={onStartTaskHandler}
              />
              <GridButton
                  icon='fa fa-pause'
                  visible={isPauseButtonVisible}
                  onClick={onPauseTaskHandler}
              />
              <GridButton
                  icon='fa fa-circle-info'
                  onClick={openTaskStatsModal}
              />
            </Column>

            <Selection mode="multiple" />
            <Toolbar>
              <Item name="groupPanel" />
              <Item>
                <Button
                    text={"Create"}
                    icon='fa fa-plus'
                    onClick={openEditTaskModal}
                />
              </Item>
              <Item>
                <Button
                    text={"Statistics"}
                    icon='fa fa-circle-info'
                    onClick={openTaskStatsModal}
                    disabled={selectedTasks.length !== 1}
                />
              </Item>
              <Item>
                <Button
                    text={"Generate report"}
                    icon='fa fa-book'
                    onClick={openGenerateReportModal}
                    disabled={selectedTasks.length === 0}
                />
              </Item>
              <Item>
                <Button
                    text={"Delete"}
                    icon='fa fa-trash'
                    type="danger"
                    disabled={selectedTasks.length === 0}
                    onClick={deleteTasks}
                />
              </Item>
            </Toolbar>
          </DataGrid>
        </div>

        <EditTask
          hideInfo={closeEditTaskModal}
          popupVisible={editTaskModalVisible}
          taskDetail={editTaskDetail}
          setEditTaskDetail={setEditTaskDetail}
          tasks={tasks}
          setTasks={setTasks}
        />

        <TaskStatistics
            hideInfo={closeTaskStatsModal}
            popupVisible={taskStatsModalVisible}
            statsDetail={taskStatsDetail}
            openGenerateReportModal={openGenerateReportModal}
        />

        <GenerateReport
            hideInfo={closeGenerateReportModal}
            popupVisible={generateReportModalVisible}
        />
      </React.Fragment>
  )
}
