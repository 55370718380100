import React, {useCallback, useEffect, useState} from "react";
import {FileUploader, Popover, SelectBox, Toast} from "devextreme-react";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import DataSource from "devextreme/data/data_source";
import {accountDataFormatsDemo, accountsGroupsDemo} from "../../../../utils/demo-data";
import './ImportFromZip.scss';

const accountGroupsDataSource = new DataSource({
    store: {
        data: accountsGroupsDemo,
        type: 'array',
        key: 'id',
    },
});

const customItemCreating = (args) => {
    if (!args.text) {
        args.customItem = null;
        return;
    }

    const itemsIds = accountsGroupsDemo.map((item) => item.id);
    const incrementedId = Math.max.apply(null, itemsIds) + 1;
    const newItem = {
        id: incrementedId,
        name: args.text,
    };

    args.customItem = accountGroupsDataSource
        .store()
        .insert(newItem)
        .then(() => accountGroupsDataSource.load())
        .then(() => newItem)
        .catch((error) => {
            throw error;
        });
};
export const ImportFromZip = ({popupVisible, hideInfo}) => {
    const [groupValue, setGroupValue] = useState();
    const [dataFormatValue, setDataFormatValue] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [importButtonDisabled, setImportButtonDisabled] = useState(true);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    const onImportFile = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: "success",
            message: `Successful import to ${groupValue.name}`,
        });
        hideInfo();
    }, [groupValue]);

    const getCancelButtonOptions = useCallback(() => ({
        text: 'Cancel',
        stylingMode: 'contained',
        type: 'danger',
        width: 120,
        onClick: hideInfo,
    }), [hideInfo]);

    const getImportButtonOptions = useCallback(() => ({
        text: 'Import',
        stylingMode: 'contained',
        type: 'success',
        width: 120,
        onClick: onImportFile,
    }), [onImportFile]);

    const groupValueChanged = useCallback(({ component }) => {
        setGroupValue(component.option('selectedItem'));
    }, []);

    const dataFormatValueChanged = useCallback(({ component }) => {
        setDataFormatValue(component.option('selectedItem'));
    }, []);

    const onSelectedFilesChanged = useCallback(
        (e) => {
            setSelectedFile(e.value);
        },
        [setSelectedFile],
    );

    const onHidingToast = useCallback(() => {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }, [toastConfig, setToastConfig]);

    useEffect(() => {
        if (!groupValue || !dataFormatValue || !selectedFile) {
            setImportButtonDisabled(true);
        } else {
            setImportButtonDisabled(false);
        }
    }, [groupValue, dataFormatValue, selectedFile]);

    return (
        <>
            <Popup
                visible={popupVisible}
                onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Import from ZIP"
                container=".dx-viewport"
                width={600}
                height={450}
            >
                <SelectBox
                    dataSource={accountGroupsDataSource}
                    displayExpr="name"
                    valueExpr="id"
                    acceptCustomValue={true}
                    onCustomItemCreating={customItemCreating}
                    onValueChanged={groupValueChanged}
                    className={'import-zip-selectbox'}
                />

                <SelectBox
                    dataSource={accountDataFormatsDemo}
                    displayExpr="name"
                    valueExpr="id"
                    onValueChanged={dataFormatValueChanged}
                    className={'import-zip-selectbox'}
                />

                <div>
                    <a id="formatDetail">Format details</a>

                    <Popover
                        className={'format-details-popover'}
                        target="#formatDetail"
                        showEvent="click"
                        position="bottom"
                        width={300}
                        shading={true}
                        shadingColor="rgba(0, 0, 0, 0.5)"
                        title="Format details"
                        showTitle={true}
                    >
                        <div className={'format-detail-block'}>
                            <strong>“phone_number”</strong> – это папка с названием, соответствующем номеру телефона;
                        </div>
                        <div className={'format-detail-block'}>
                            <strong>“/”</strong> – означает нахождение того, что следует за ним, во вложенной папке;
                        </div>
                        <div className={'format-detail-block'}>
                            <strong>“+”</strong> – означает нахождение того, что находится по обе стороны от него в одной и той же папке;
                        </div>
                        <div className={'format-detail-block'}>
                            <strong>“2fa.txt”</strong> – файл, содержащий данные о двухфакторной аутентификации;
                        </div>
                        <div className={'format-detail-block'}>
                            <strong>“account.txt”</strong> – файл, содержащий данные об аккаунте.
                        </div>
                    </Popover>
                </div>

                <FileUploader
                    multiple={false}
                    accept={'.zip'}
                    uploadMode={'instantly'}
                    onValueChanged={onSelectedFilesChanged}
                    selectButtonText="Select ZIP file"
                    className={'import-uploader'}
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    disabled={importButtonDisabled}
                    options={getImportButtonOptions()}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={getCancelButtonOptions()}
                />
            </Popup>
            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHidingToast}
                displayTime={1000}
                position={"top"}
            />
        </>
    );
}
