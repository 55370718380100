import React, {useCallback, useMemo, useRef, useState} from 'react';
import './messages.scss';
import DataGrid, {
    Button as GridButton,
    Column,
    Editing,
    HeaderFilter,
    Item,
    Lookup,
    Pager,
    Paging,
    RequiredRule,
    Selection,
    Toolbar
} from "devextreme-react/data-grid";
import {messagesDemo, messagesTypesDemo} from "../../utils/demo-data";
import {Button} from "devextreme-react";
import {confirm} from "devextreme/ui/dialog";
import {
    AddMessageConfirmation
} from "../../components/modals/messages-page/add-message-confirmation/AddMessageConfirmation";
import {AddMessage} from "../../components/modals/messages-page/add-message/AddMessage";
import {EditMessage} from "../../components/modals/messages-page/edit-message/EditMessage";
import {EditInvite} from "../../components/modals/messages-page/edit-invite/EditInvite";

export default function Messages() {
    const dataGridRef = useRef(null);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [messages, setMessages] = useState(messagesDemo);
    const [messageType, setMessageType] = useState(undefined);
    const [addMessageConfirmationModalVisible, setAddMessageConfirmationModalVisible] = useState(false);
    const [addMessageModalVisible, setAddMessageModalVisible] = useState(false);
    const [editMessageModalVisible, setEditMessageModalVisible] = useState(false);
    const [editMessageDetail, setEditMessageDetail] = useState(undefined);
    const [editInviteModalVisible, setEditInviteModalVisible] = useState(false);
    const [editInviteDetail, setEditInviteDetail] = useState(undefined);

    const openAddMessageConfirmationModalHandler = useCallback(() => {
        setAddMessageConfirmationModalVisible(true);
    }, [setAddMessageConfirmationModalVisible]);

    const closeAddMessageConfirmationModalHandler = useCallback(() => {
        setAddMessageConfirmationModalVisible(false);
    }, [setAddMessageConfirmationModalVisible]);

    const openAddMessageModalHandler = useCallback(() => {
        setAddMessageModalVisible(true);
    }, [setAddMessageModalVisible]);

    const closeAddMessageModalHandler = useCallback(() => {
        setAddMessageModalVisible(false);
    }, [setAddMessageModalVisible]);

    const openEditModalHandler = useCallback((e) => {
        if (e.row.data.typeOfMessageId !== 6) {
            setEditMessageDetail(e.row.data);
            setEditMessageModalVisible(true);
        } else {
            setEditInviteDetail(e.row.data);
            setEditInviteModalVisible(true);
        }
    }, [setEditMessageModalVisible, setEditInviteModalVisible]);

    const closeEditMessageModalHandler = useCallback(() => {
        setMessageType(undefined);
        setEditMessageModalVisible(false);
    }, [setAddMessageConfirmationModalVisible]);

    const openEditInviteModalHandler = useCallback(() => {
        setEditInviteModalVisible(true);
    }, [setAddMessageConfirmationModalVisible]);

    const closeEditInviteModalHandler = useCallback(() => {
        setEditInviteModalVisible(false);
    }, [setAddMessageConfirmationModalVisible]);

    const handleSelectionChanged = (e) => {
        setSelectedMessages(e.selectedRowKeys)
    };

    const deleteMessages = useCallback(async () => {
        const confirmed = await confirm('Do you confirm deleting messages and invites?', 'Are you sure?');

        if (confirmed) {
            selectedMessages.forEach((messageId) => {
                setMessages(prevState => prevState.filter((message) => message.id !== messageId));
            })
            dataGridRef.current.instance.deselectAll();
        }
    }, [dataGridRef, selectedMessages]);

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Messages and invites</h2>
            <div className={'content-block'}>
                <DataGrid
                    id={'messagesGrid'}
                    ref={dataGridRef}
                    className={'dx-card wide-card'}
                    dataSource={messages}
                    keyExpr={"id"}
                    showBorders={false}
                    focusedRowEnabled={false}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    columnHidingEnabled={true}
                    onSelectionChanged={handleSelectionChanged}
                >
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} />
                    <Editing
                        mode="popup"
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                    />
                    <HeaderFilter visible={true} />

                    <Column dataField='messageName'>
                        <RequiredRule />
                    </Column>
                    <Column
                        dataField="typeOfMessageId"
                        caption="Type of message"
                    >
                        <RequiredRule />
                        <Lookup
                            dataSource={messagesTypesDemo}
                            displayExpr="name"
                            valueExpr="id"
                        />
                    </Column>
                    <Column type='buttons'>
                        <GridButton
                            name='edit'
                            onClick={openEditModalHandler}
                        />
                        <GridButton name='delete' />
                    </Column>

                    <Selection mode="multiple" />
                    <Toolbar>
                        <Item name="groupPanel" />
                        <Item>
                            <Button
                                text={"Create message"}
                                icon='fa fa-plus'
                                onClick={openAddMessageConfirmationModalHandler}
                            />
                        </Item>
                        <Item>
                            <Button
                                text={"Create invite"}
                                icon='fa fa-plus'
                                onClick={openEditInviteModalHandler}
                            />
                        </Item>
                        <Item>
                            <Button
                                text={"Delete"}
                                icon='fa fa-trash'
                                type="danger"
                                disabled={selectedMessages.length === 0}
                                onClick={deleteMessages}
                            />
                        </Item>
                    </Toolbar>
                </DataGrid>
            </div>

            <AddMessageConfirmation
                hideInfo={closeAddMessageConfirmationModalHandler}
                popupVisible={addMessageConfirmationModalVisible}
                openNextPopup={openAddMessageModalHandler}
                messageType={messageType}
                setMessageType={setMessageType}
            />

            <AddMessage
                hideInfo={closeAddMessageModalHandler}
                popupVisible={addMessageModalVisible}
                messageType={messageType}
                messages={messages}
                setMessages={setMessages}
            />

            <EditMessage
                hideInfo={closeEditMessageModalHandler}
                popupVisible={editMessageModalVisible}
                messageDetail={editMessageDetail}
                messages={messages}
                setMessages={setMessages}
            />

            <EditInvite
                hideInfo={closeEditInviteModalHandler}
                popupVisible={editInviteModalVisible}
                inviteDetail={editInviteDetail}
                setEditInviteDetail={setEditInviteDetail}
                messages={messages}
                setMessages={setMessages}
            />
        </React.Fragment>
    )
};
