import React, {useCallback, useMemo, useState} from "react";
import {RadioGroup} from "devextreme-react";
import {tasksGenerateReportTypesDemo} from "../../../../utils/demo-data";
import {Popup, ToolbarItem} from "devextreme-react/popup";
import {saveFile} from "../../../../utils/helpers";

export const GenerateReport = (props) => {
    const {
        hideInfo,
        popupVisible,
    } = props;
    const [generateType, setGenerateType] = useState();

    const exportOptions = useMemo(() => {
        return {
            type: 'success',
            stylingMode: 'contained',
            text:'Export',
            onClick: () => {
                const rows = [
                    ["report1", "task1", "yesno1"],
                    ["report2", "task2", "yesno2"],
                    ["report3", "task3", "yesno3"],
                ];

                let csvContent = "data:text/csv;charset=utf-8,"
                    + rows.map(e => e.join(",")).join("\n");
                saveFile('report-task', csvContent);
                setGenerateType(undefined);
                hideInfo();
            },
        }
    }, []);
    const cancelOptions = useMemo(() => {
        return {
            type: 'danger',
            stylingMode: 'contained',
            text:'Cancel',
            onClick: ()=>{
                setGenerateType(undefined);
                hideInfo();
            },
        }
    }, []);

    const changeGenerateType = useCallback((e) => {
        setGenerateType(e.value);
    }, [setGenerateType]);

    return (
        <Popup
            visible={popupVisible}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            showTitle={true}
            title={'Generate report'}
            container=".dx-viewport"
            width={400}
            height={350}
        >

            <RadioGroup
                items={tasksGenerateReportTypesDemo}
                value={generateType}
                valueExpr="id"
                displayExpr="name"
                onValueChanged={changeGenerateType}
            />

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={exportOptions}
                disabled={!generateType}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={cancelOptions}
            />
        </Popup>
    )
}
