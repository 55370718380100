import CryptoJS from 'crypto-js';
export function saveFile(fileName, urlFile){
    let a = document.createElement("a");
    a.style = 'display: none';
    document.body.appendChild(a);
    a.href = urlFile;
    a.download = fileName;
    a.click();
    a.remove();
}

function sha256(str) {
    return CryptoJS.SHA256(str).toString();
}

// export async function checkTelegramAuth(user) {
//     const authHash = user.hash;
//     const userInfo = { ...user };
//     delete userInfo.hash;
//
//     const dataCheckArr = [];
//     Object.keys(userInfo).map((key) => {
//         dataCheckArr.push(`${key}=${userInfo[key]}`)
//     })
//     const dataCheckString = dataCheckArr.sort().join('\n');
//     const secretKey = sha256('6709783909:AAEk6hb6MSZvbeTJRdWzA-OPgsq00tJ0h0A');
//     const hash_hmac = CryptoJS.HmacSHA256(dataCheckString, secretKey).toString(CryptoJS.enc.Hex);
//
//     if (hash_hmac === authHash) {
//         return 'true';
//     }
//
//     return 'false';
// }

export async function checkTelegramAuthorization(auth_data) {
    const check_hash = auth_data.hash;
    delete auth_data.hash;
    const data_check_arr = [];
    for (const key in auth_data) {
        if (auth_data.hasOwnProperty(key)) {
            data_check_arr.push(key + '=' + auth_data[key]);
        }
    }
    data_check_arr.sort();
    const data_check_string = data_check_arr.join("\n");
    const secret_key = sha256('6709783909:AAEk6hb6MSZvbeTJRdWzA-OPgsq00tJ0h0A');
    const hmac = CryptoJS.HmacSHA256(data_check_string, secret_key).toString(CryptoJS.enc.Hex);

    if (hmac !== check_hash) {
        throw new Error('Data is NOT from Telegram');
    }
    if ((Date.now() / 1000 - auth_data.auth_date) > 86400) {
        throw new Error('Data is outdated');
    }
    return auth_data;
}
